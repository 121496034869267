import React from 'react'
import { connect } from 'react-redux'
import Card from 'shared/components/CourseReviews/Card'
import LoaderSpinner from 'shared/components/LoaderSpinner'
import StarRating from 'shared/components/StarsRating'
import TablePaging from 'shared/components/TablePaging/TablePaging'
import { ICourseReview } from 'shared/proto/models'
import { fetchReviews } from 'shared/reducers/courseInfo/courseReviewReducer'

import { AppDispatch, IAppState } from '../../reducers/rootReducer'
import {
  cardItem,
  cardList,
  header,
  headerRating,
  headerRatingInfo,
  headerRatingText,
  headerTitle,
  headerWrapper,
  paging,
  sectionContent,
} from './CourseReviews.module.scss'

interface ICourseReviewsPageProps {
  dispatch: AppDispatch
  courseId?: string
  path: string
  loading: boolean
  totalGrade: number
  reviews?: ICourseReview[]
  reviewsCount?: number
  onFetchData: (courseId: string, pageIndex: number, pageSize: number) => void
}

const formatter = new Intl.NumberFormat('ru', { minimumFractionDigits: 2, maximumFractionDigits: 2 })

const EmployeeCourseReviewsPage = (props: ICourseReviewsPageProps): React.ReactElement<ICourseReviewsPageProps> => {
  const pageSizeOptions = [25, 50, 75]
  const [pageSize, setPageSize] = React.useState(pageSizeOptions[0])
  const [pageIndex, setPageIndex] = React.useState(0)

  const gotoPage = (pageNum: number) => {
    setPageIndex(pageNum)
  }

  const changePageSize = (pageSize: number) => {
    setPageSize(pageSize)
    setPageIndex(pageSize > props.reviewsCount ? 0 : pageIndex)
  }

  React.useEffect(() => {
    props.onFetchData(props.courseId, pageIndex, pageSize)
  }, [pageIndex, pageSize])

  return (
    <section className={sectionContent}>
      {props.loading ? (
        <LoaderSpinner />
      ) : (
        <>
          <header className={header}>
            <div className={headerWrapper}>
              <div className={headerRatingInfo}>
                <h2 className={headerTitle}>Общая оценка курса:</h2>
                <StarRating totalGrade={props.totalGrade} />
              </div>

              <div className={headerRating}>
                <strong className={headerRatingText}>
                  {props.totalGrade ? formatter.format(props.totalGrade) : 0}
                </strong>
              </div>
            </div>
          </header>
          <ul className={cardList}>
            {props.reviews ? (
              props.reviews.map((item) => (
                <li key={`card-${item.id}`} className={cardItem}>
                  <Card
                    fullName={item.fullName}
                    reviewText={item.reviewText}
                    grade={item.grade}
                    date={item.date}
                    profileImg={item.profileImg}
                  />
                </li>
              ))
            ) : (
              <></>
            )}
          </ul>
        </>
      )}
      <div className={paging}>
        <TablePaging
          gotoPage={gotoPage}
          pageCount={Math.floor(props.reviewsCount / pageSize) + 1}
          pageIndex={pageIndex}
          pageSizeOptions={pageSizeOptions}
          setPageSize={changePageSize}
        />
      </div>
    </section>
  )
}

const mapStateToProps = (state: IAppState) => ({
  dispatch: state.dispatch,
  reviews: state.courseReview.reviews,
  totalGrade: state.courseReview.totalGrade,
  loading: state.courseReview.loading,
  reviewsCount: state.courseReview.reviewsCount,
})

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    onFetchData: (courseId: string, pageIndex: number, pageSize: number) => {
      const url = 'manufacturer-office/employee-course/get-reviews/'
      dispatch(fetchReviews(url, courseId, pageIndex, pageSize))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeCourseReviewsPage)
