import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { Dispatch } from 'redux'

import { createAction, createReducer } from '@reduxjs/toolkit'
import { AccountBalance, IAccountBalance, IPagingAndSort } from 'shared/proto/models'

import {
  Employee,
  EmployeeCourseListResponse,
  IEmployee,
  IEmployeeCourse,
  IEmployeeCourseListResponse,
  IEmployeeCourseRequest,
  IManufacturerSubdivisionInfo,
  IManufacturerSubdivisionListResponse,
  IManufacturerSubdivisionResponse,
  IManufacturerSubdivisionSearch,
  IManufacturerSubdivisionSearchListResponse,
  IManufacturerSubdivisionStaff,
  IManufacturerSubdivisionStaffList,
  IStaffAddRequest,
  IStaffToAdd,
  IStaffToAddList,
  IUserManufacturer,
  IUserManufacturerResponse,
  ManufacturerSubdivision as ManufacturerSubdivisionType,
  ManufacturerSubdivisionInfo,
  ManufacturerSubdivisionListResponse,
  ManufacturerSubdivisionResponse,
  ManufacturerSubdivisionSearchListResponse,
  ManufacturerSubdivisionStaffList,
  StaffAddRequest,
  StaffToAddList,
  UserManufacturerResponse,
} from '../proto/models'
import { IAppState } from './rootReducer'

const url = 'manufacturer-office'

interface ISubdivisionStaffState {
  info: IManufacturerSubdivisionInfo
  loadInfo: boolean
  employees: IManufacturerSubdivisionStaff[]
  loadTable: boolean
  addEmployees: IStaffToAdd[]
  loadAddTable: boolean
}

interface ISubdivisionEmployeeState {
  info: IEmployee
  loadInfo: boolean
  courses: IEmployeeCourse[]
  loadCourses: boolean
}

interface ISubdivisionState {
  notificationOpen: boolean
  notificationMessage: string
  loadTable: boolean
  search: IManufacturerSubdivisionSearch[]
  staff: ISubdivisionStaffState
  employee: ISubdivisionEmployeeState
}

interface IAccountState {
  balance: IAccountBalance
  loadBalance: boolean
}

export interface IManufacturerState {
  info: IUserManufacturer
  loadInfo: boolean
  subdivision: ISubdivisionState
  account: IAccountState
}

const initialState: IManufacturerState = {
  info: null,
  loadInfo: true,
  subdivision: {
    notificationMessage: '',
    notificationOpen: false,
    loadTable: true,
    search: [],
    staff: {
      info: null,
      loadInfo: true,
      employees: [],
      loadTable: true,
      addEmployees: [],
      loadAddTable: true,
    },
    employee: {
      info: null,
      loadInfo: true,
      courses: [],
      loadCourses: true,
    },
  },
  account: {
    balance: null,
    loadBalance: true,
  },
}

const LOAD_INFO = 'LOAD_INFO'

const SET_INFO = 'SET_INFO'
export const getInfo = () => async (dispatch: Dispatch): Promise<void> => {
  const response = await ProtoClient.get<IUserManufacturerResponse>(
    url + '/subdivisions-list/user-manufacturer',
    UserManufacturerResponse
  )

  if (response.success) {
    dispatch({ type: SET_INFO, payload: response.payload })
  } else {
    const message = `Невозможно открыть страницу. ${response.message}`
    dispatch({ type: SET_NOTIFICATION_SUBDIVISION, payload: { open: true, message: message } })
  }

  dispatch({ type: LOAD_INFO, payload: false })
}

const SET_NOTIFICATION_SUBDIVISION = 'SET_NOTIFICATION_SUBDIVISION'
export const setNotificationSubdivision = (open: boolean, message: string) => async (
  dispatch: Dispatch
): Promise<void> => {
  dispatch({ type: SET_NOTIFICATION_SUBDIVISION, payload: { open, message } })
}

const LOAD_TABLE_SUBDIVISION = 'LOAD_TABLE_SUBDIVISION'
export const setLoadTableSubdivision = (value: boolean) => async (dispatch: Dispatch): Promise<void> => {
  dispatch({ type: LOAD_TABLE_SUBDIVISION, payload: value })
}

export const getSubdivision = (paging: IPagingAndSort) => async (
  dispatch: Dispatch
): Promise<IManufacturerSubdivisionListResponse> => {
  const response = await ProtoClient.get<IManufacturerSubdivisionListResponse>(
    url + '/subdivisions-list',
    ManufacturerSubdivisionListResponse,
    paging
  )

  dispatch({ type: LOAD_TABLE_SUBDIVISION, payload: false })
  return response
}

export const deleteSubdivision = (id: string) => async (dispatch: Dispatch): Promise<void> => {
  dispatch({ type: LOAD_TABLE_SUBDIVISION, payload: true })

  await ProtoClient.delete(url + '/subdivisions-list', id)
}

export interface ISubdivisionFormValues {
  id?: Uint8Array
  manufacturerSubdivisionName: string
  parentManufacturerSubdivision: {
    id: Uint8Array
    name: string
  }
}

export const saveOrUpdateSubdivisions = (
  values: ISubdivisionFormValues,
  save: boolean
) => async (): Promise<IManufacturerSubdivisionResponse> => {
  let response

  const data = ManufacturerSubdivisionType.create({
    id: values.id ?? null,
    name: values.manufacturerSubdivisionName,
    parentId: values.parentManufacturerSubdivision?.id ?? null,
  })

  if (save) {
    response = await ProtoClient.post<IManufacturerSubdivisionResponse>(
      url + '/subdivisions-list',
      data,
      ManufacturerSubdivisionType,
      ManufacturerSubdivisionResponse
    )
  } else {
    response = await ProtoClient.put<IManufacturerSubdivisionResponse>(
      url + '/subdivisions-list',
      data,
      ManufacturerSubdivisionType,
      ManufacturerSubdivisionResponse
    )
  }

  return response
}

const SEARCH_SUBDIVISION = 'SEARCH_SUBDIVISION'
export const searchSubdivision = (searchString: string, organizationId: string) => async (
  dispatch: Dispatch
): Promise<void> => {
  if (!searchString || searchString === '') {
    return
  }

  const res = await ProtoClient.get<IManufacturerSubdivisionSearchListResponse>(
    url + '/subdivisions-list/search',
    ManufacturerSubdivisionSearchListResponse,
    {
      search: searchString,
      organizationId: organizationId,
    }
  )

  dispatch({ type: SEARCH_SUBDIVISION, payload: res?.items ?? [] })
}

const LOAD_BALANCE_ACCOUNT = 'LOAD_BALANCE_ACCOUNT'

const GET_ACCOUNT_BALANCE = 'GET_ACCOUNT_BALANCE'
export const getAccountBalance = () => async (dispatch: Dispatch): Promise<void> => {
  const response = await ProtoClient.get<IAccountBalance>(url + '/manufacturer-account/account-balance', AccountBalance)

  dispatch({ type: GET_ACCOUNT_BALANCE, payload: response })
  dispatch({ type: LOAD_BALANCE_ACCOUNT, payload: false })
}

const LOAD_STAFF_INFO = 'LOAD_STAFF_INFO'
const GET_STAFF_INFO = 'GET_STAFF_INFO'
export const getStaffInfo = (id: string) => async (dispatch: Dispatch, getState: () => IAppState): Promise<void> => {
  const loader = getState().manufacturer.loadInfo

  if (!loader) {
    dispatch({ type: LOAD_STAFF_INFO, payload: true })
  }

  const response = await ProtoClient.get<IManufacturerSubdivisionInfo>(
    url + `/subdivisions-list/user-manufacturer/${id}`,
    ManufacturerSubdivisionInfo
  )

  dispatch({ type: LOAD_STAFF_INFO, payload: false })
  dispatch({ type: GET_STAFF_INFO, payload: response })
}

export interface IEmployeesRequest {
  pageIndex: number
  pageSize: number
  sortColumn: string
  sortDirection: string
  subdivisionId: string
  searchQuery: string
}

const LOAD_EMPLOYEES = 'LOAD_EMPLOYEES'
export const setLoadEmployees = (value: boolean) => async (dispatch: Dispatch): Promise<void> => {
  dispatch({ type: LOAD_EMPLOYEES, payload: value })
}

const GET_EMPLOYEES = 'GET_EMPLOYEES'
export const getEmployees = (options: IEmployeesRequest) => async (
  dispatch: Dispatch
): Promise<IManufacturerSubdivisionStaffList> => {
  const response = await ProtoClient.get<IManufacturerSubdivisionStaffList>(
    url + '/subdivisions-staff',
    ManufacturerSubdivisionStaffList,
    options
  )

  dispatch({ type: LOAD_EMPLOYEES, payload: false })
  dispatch({ type: GET_EMPLOYEES, payload: response.items })

  return response
}

export const deleteEmployee = (id: string) => async (dispatch: Dispatch): Promise<void> => {
  dispatch({ type: LOAD_EMPLOYEES, payload: true })

  await ProtoClient.delete(url + '/subdivisions-staff', id)
}

export const addEmployee = (options: IStaffAddRequest) => async (dispatch: Dispatch): Promise<void> => {
  dispatch({ type: LOAD_EMPLOYEES, payload: true })

  await ProtoClient.post(url + '/subdivisions-staff/add', options, StaffAddRequest)
}

export interface IAddEmployeesRequest {
  searchString: string
}

const LOAD_ADD_EMPLOYEES = 'LOAD_ADD_EMPLOYEES'
const GET_ADD_EMPLOYEES = 'GET_ADD_EMPLOYEES'
export const getAddEmployees = (options: IAddEmployeesRequest) => async (
  dispatch: Dispatch,
  getState: () => IAppState
): Promise<IManufacturerSubdivisionStaffList> => {
  const load = getState().manufacturer.subdivision.staff.loadAddTable

  if (!load) {
    dispatch({ type: LOAD_ADD_EMPLOYEES, payload: true })
  }

  const response = await ProtoClient.get<IStaffToAddList>(
    url + '/subdivisions-staff/staff-to-add',
    StaffToAddList,
    options
  )

  dispatch({ type: LOAD_ADD_EMPLOYEES, payload: false })
  dispatch({ type: GET_ADD_EMPLOYEES, payload: response.items })

  return response
}

const LOAD_EMPLOYEE_INFO = 'LOAD_EMPLOYEE_INFO'
const GET_EMPLOYEE_INFO = 'GET_EMPLOYEE_INFO'
export const getEmployeeInfo = (id: string) => async (dispatch: Dispatch): Promise<void> => {
  const response = await ProtoClient.get<IEmployee>(url + '/employee/get-employee', Employee, { id: id })

  dispatch({ type: LOAD_EMPLOYEE_INFO, payload: false })
  dispatch({ type: GET_EMPLOYEE_INFO, payload: response })
}

const LOAD_EMPLOYEE_COURSES = 'LOAD_EMPLOYEE_COURSES'
export const setLoadEmployeeCourses = (value: boolean) => async (dispatch: Dispatch): Promise<void> => {
  dispatch({ type: LOAD_EMPLOYEE_COURSES, payload: value })
}

const GET_EMPLOYEE_COURSES = 'GET_EMPLOYEE_COURSES'
export const getEmployeeCourses = (options: IEmployeeCourseRequest) => async (
  dispatch: Dispatch,
  getState: () => IAppState
): Promise<IEmployeeCourseListResponse> => {
  const load = getState().manufacturer.subdivision.employee.loadCourses
  if (!load) {
    dispatch({ type: LOAD_EMPLOYEE_COURSES, payload: true })
  }

  const response = await ProtoClient.get<IEmployeeCourseListResponse>(
    url + '/employee-course/get-courses',
    EmployeeCourseListResponse,
    options
  )

  dispatch({ type: LOAD_EMPLOYEE_COURSES, payload: false })
  dispatch({ type: GET_EMPLOYEE_COURSES, payload: response.items })

  return response
}

const RESET_STATE_SUBDIVISION = 'RESET_STATE_SUBDIVISION'
export const resetStateSubdivision = createAction(RESET_STATE_SUBDIVISION)

const RESET_STATE_SEARCH_SUBDIVISION = 'RESET_STATE_SEARCH_SUBDIVISION'
export const resetSearchSubdivisionState = createAction(RESET_STATE_SEARCH_SUBDIVISION)

const RESET_STATE_STAFF = 'RESET_STATE_STAFF'
export const resetStateStaff = createAction(RESET_STATE_STAFF)

const RESET_STATE_EMPLOYEE = 'RESET_STATE_EMPLOYEE'
export const resetStateEmployee = createAction(RESET_STATE_EMPLOYEE)

const manufacturerReducer = createReducer(initialState, {
  [SET_INFO]: (state, action) => {
    state.info = action.payload
  },
  [LOAD_INFO]: (state, action) => {
    state.loadInfo = action.payload
  },
  [SET_NOTIFICATION_SUBDIVISION]: (state, action) => {
    state.subdivision.notificationMessage = action.payload.message
    state.subdivision.notificationOpen = action.payload.open
  },
  [LOAD_TABLE_SUBDIVISION]: (state, action) => {
    state.subdivision.loadTable = action.payload
  },
  [SEARCH_SUBDIVISION]: (state, action) => {
    state.subdivision.search = action.payload
  },
  [RESET_STATE_SEARCH_SUBDIVISION]: (state) => {
    state.subdivision.search = initialState.subdivision.search
  },
  [GET_ACCOUNT_BALANCE]: (state, action) => {
    state.account.balance = action.payload
  },
  [LOAD_BALANCE_ACCOUNT]: (state, action) => {
    state.account.loadBalance = action.payload
  },
  [GET_STAFF_INFO]: (state, action) => {
    state.subdivision.staff.info = action.payload
  },
  [LOAD_STAFF_INFO]: (state, action) => {
    state.subdivision.staff.loadInfo = action.payload
  },
  [RESET_STATE_STAFF]: (state) => {
    state.subdivision.staff = initialState.subdivision.staff
  },
  [LOAD_EMPLOYEES]: (state, action) => {
    state.subdivision.staff.loadTable = action.payload
  },
  [GET_EMPLOYEES]: (state, action) => {
    state.subdivision.staff.employees = action.payload
  },
  [LOAD_ADD_EMPLOYEES]: (state, action) => {
    state.subdivision.staff.loadAddTable = action.payload
  },
  [GET_ADD_EMPLOYEES]: (state, action) => {
    state.subdivision.staff.addEmployees = action.payload
  },
  [GET_EMPLOYEE_INFO]: (state, action) => {
    state.subdivision.employee.info = action.payload
  },
  [LOAD_EMPLOYEE_INFO]: (state, action) => {
    state.subdivision.employee.loadInfo = action.payload
  },
  [RESET_STATE_EMPLOYEE]: (state) => {
    state.subdivision.employee = initialState.subdivision.employee
  },
  [LOAD_EMPLOYEE_COURSES]: (state, action) => {
    state.subdivision.employee.loadCourses = action.payload
  },
  [GET_EMPLOYEE_COURSES]: (state, action) => {
    state.subdivision.employee.courses = action.payload
  },
  [RESET_STATE_SUBDIVISION]: (state) => {
    state.subdivision = initialState.subdivision
  },
})

export default manufacturerReducer
