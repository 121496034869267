import React, { useEffect } from 'react'
import nextId from 'react-id-generator'
import { connect } from 'react-redux'

import LoaderSpinner from 'shared/components/LoaderSpinner'
import NavigationTab, { IDataNavigationTab } from 'shared/components/NavigationTab/NavigationTab'
import Notification from 'shared/components/Notification'
import PageInfoHeader, { IPageInfoHeader } from 'shared/components/PageInfoHeader'
import PageTitle from 'shared/components/PageTitle'

import { IUserManufacturer } from '../../../proto/models'
import { getInfo, setNotificationSubdivision } from '../../../reducers/manufacturerReducer'
import { AppDispatch, IAppState } from '../../../reducers/rootReducer'
import { withPrefix } from 'gatsby'

interface IManufacturerInfoOwnProps {
  path: string
  children?: React.ReactNode
}

interface IManufacturerInfoStateProps {
  dispatch: AppDispatch
  info: IUserManufacturer
  loadInfo: boolean
  notificationOpenSubdivision: boolean
  notificationMessageSubdivision: string
}

type ManufacturerInfoProps = IManufacturerInfoOwnProps & IManufacturerInfoStateProps

const getDataPageInfoHeader = (info: IUserManufacturer) => {
  return [
    {
      id: nextId(),
      title: 'Наименование компании',
      value: info?.shortName,
    },
    {
      id: nextId(),
      title: 'Номер договора',
      value: info?.contractCode,
    },
  ]
}

const getDataNavigation = () => {
  return [
    {
      id: nextId(),
      name: 'Подразделения',
      link: withPrefix(`manufacturer-office/organization`),
    },
    {
      id: nextId(),
      name: 'Счета',
      link: withPrefix(`manufacturer-office/organization/manufacturer-account`),
    },
  ]
}

const onNotificationClick = (dispatch: AppDispatch) => {
  dispatch(setNotificationSubdivision(false, ''))
}

const ManufacturerInfo = (props: ManufacturerInfoProps) => {
  const dataNavigation: IDataNavigationTab[] = getDataNavigation()
  const dataPageInfoHeader: IPageInfoHeader[] = getDataPageInfoHeader(props.info)

  useEffect(() => {
    if (!props.info) {
      props.dispatch(getInfo())
    }
  }, [])

  return (
    <>
      <Notification
        open={props.notificationOpenSubdivision}
        onClose={() => onNotificationClick(props.dispatch)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        text={props.notificationMessageSubdivision}
        response="negative"
        autoHideDuration={6000}
      />

      {props.loadInfo ? (
        <LoaderSpinner />
      ) : (
        <>
          <PageTitle title="Кабинет производителя" />
          <PageInfoHeader data={dataPageInfoHeader} />
          <NavigationTab data={dataNavigation} />
          {props.children}
        </>
      )}
    </>
  )
}

const mapStateToProps = (store: IAppState) => {
  return {
    dispatch: store.dispatch,
    info: store.manufacturer.info,
    notificationOpenSubdivision: store.manufacturer.subdivision.notificationOpen,
    notificationMessageSubdivision: store.manufacturer.subdivision.notificationMessage,
    loadInfo: store.manufacturer.loadInfo,
  }
}

export default connect<IManufacturerInfoStateProps, {}, IManufacturerInfoOwnProps, IAppState>(mapStateToProps)(
  ManufacturerInfo
)
