import classNames from 'classnames'
import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import { Link, withPrefix } from 'gatsby'
import React, { ReactElement, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Cell,
  Column,
  SortingRule,
  TableInstance,
  TableOptions,
  TableState,
  useAsyncDebounce,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationOptions,
  UsePaginationState,
  useSortBy,
  UseSortByOptions,
  UseSortByState,
  useTable,
} from 'react-table'
import PreviewIcon from 'shared/components/Icons/PreviewIcon'
import SearchInput from 'shared/components/SearchInput'
import Table from 'shared/components/Table'
import {
  tableButtons,
  tableCell,
  tableText,
  tableTextContainer,
  tableTextGreen,
  tableTextRed,
} from 'shared/components/Table/Table.module.scss'
import TablePaging from 'shared/components/TablePaging/TablePaging'
import { statusToLocale } from 'shared/helpers/statusHelper'

import { ECourseStatus, IEmployeeCourse, IEmployeeCourseRequest } from '../../../../proto/models'
import { getEmployeeCourses } from '../../../../reducers/manufacturerReducer'
import { AppDispatch, IAppState } from '../../../../reducers/rootReducer'
import {
  section,
  sectionContent,
  sectionContentHeader,
  sectionSearch,
  tableCellCategory,
  tableCellEdit,
  tableCellName,
  tableCellStatus,
} from './Employee.module.scss'

interface IEmployeeTableOwnProps {
  employeeId?: string
  subdivisionId?: string
  path: string
}

interface IEmployeeTableStateProps {
  dispatch: AppDispatch
  loadCourses: boolean
}

type EmployeeTableProps = IEmployeeTableOwnProps & IEmployeeTableStateProps

const pageSizeOptions = [25, 50, 75]

const EmployeeCourseTable = (props: EmployeeTableProps): ReactElement<EmployeeTableProps> => {
  const [fetchedData, setFetchedData] = useState<IEmployeeCourse[]>([])
  const [totalRowCount, setTotalRowCount] = useState(0)
  const [search, setSearch] = useState('')

  interface FetchDataProps {
    pageIndex: number
    pageSize: number
    sortBy: Array<SortingRule<IEmployeeCourse>>
    search: string
  }

  const getCellClass = (column: Column<IEmployeeCourse>) => {
    switch (column.id) {
      case 'name': {
        return classNames(tableCell, tableCellName)
      }
      case 'businessCategory': {
        return classNames(tableCell, tableCellCategory)
      }
      case 'status': {
        return classNames(tableCell, tableCellStatus)
      }
      case 'edit': {
        return classNames(tableCell, tableCellEdit)
      }
    }
  }

  const onFetchData = async ({ pageIndex, pageSize, sortBy, search }: FetchDataProps) => {
    const paging: IEmployeeCourseRequest = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortColumn: sortBy[0]?.id || 'name',
      sortDirection: sortBy[0]?.desc ?? true ? 'desc' : 'asc',
      employeeId: props.employeeId,
      searchQuery: search,
    }

    const response = await props.dispatch(getEmployeeCourses(paging))

    setFetchedData(response?.items ?? [])
    setTotalRowCount(response?.rowCount ?? 0)
  }

  const columns: Column<IEmployeeCourse>[] = React.useMemo(
    () => [
      {
        Header: 'Наименование',
        id: 'name',
        accessor: 'name',
        sortType: 'basic',
        defaultCanSort: true,
        // eslint-disable-next-line react/display-name
        Cell: (cell: Cell<IEmployeeCourse>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText}>{cell.value}</span>
            </p>
          )
        },
      },
      {
        Header: 'Категории',
        id: 'businessCategory',
        accessor: 'businessCategory',
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: (cell: Cell<IEmployeeCourse>) => {
          return (
            <>
              {cell.row.original.businessCategory && (
                <p className={tableTextContainer}>
                  <span className={tableText}>{cell.row.original.businessCategory}</span>
                </p>
              )}
              {cell.row.original.medicalCategory && (
                <p className={tableTextContainer}>
                  <span className={tableText}>{cell.row.original.medicalCategory}</span>
                </p>
              )}
            </>
          )
        },
      },
      {
        Header: 'Статус',
        id: 'status',
        accessor: 'status',
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: (cell: Cell<IEmployeeCourse>) => (
          <p className={tableTextContainer}>
            <span
              className={classNames(tableText, {
                [tableTextGreen]: cell.value === ECourseStatus.Approved,
                [tableTextRed]: cell.value === ECourseStatus.Rejected,
              })}
            >
              {statusToLocale(cell.value)}
            </span>
          </p>
        ),
      },
      {
        id: 'edit',
        accessor: 'id',
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: ({ row }) => (
          <div className={tableButtons}>
            <Link
              to={withPrefix(
                `manufacturer-office/organization/subdivision/${props.subdivisionId}/employee/${
                  props.employeeId
                }/course/${GuidHelper.toString(row.original.id)}/info`
              )}
            >
              <PreviewIcon />
            </Link>
          </div>
        ),
      },
    ],
    [props.employeeId, props.subdivisionId]
  )

  const [localPageSize, setLocalPageSize] = React.useState(pageSizeOptions[0])

  const data = React.useMemo(() => {
    return fetchedData
  }, [fetchedData])

  const tablePageCount = React.useMemo(
    () => Math.ceil(totalRowCount / (localPageSize || pageSizeOptions[0])),
    [totalRowCount, localPageSize]
  )

  const initialState: Partial<TableState<IEmployeeCourse>> &
    Partial<UsePaginationState<IEmployeeCourse>> &
    UseSortByState<IEmployeeCourse> = {
    pageSize: localPageSize,
    sortBy: [{ id: 'name', desc: true }],
  }

  const tableOptions: TableOptions<IEmployeeCourse> &
    UseSortByOptions<IEmployeeCourse> &
    UsePaginationOptions<IEmployeeCourse> = {
    data: data,
    columns: columns,
    pageCount: tablePageCount,
    initialState: initialState,
    manualSortBy: true,
    manualPagination: true,
    disableSortRemove: true,
  }

  const table = useTable<IEmployeeCourse>(tableOptions, useSortBy, usePagination) as TableInstance<IEmployeeCourse> &
    UsePaginationInstanceProps<IEmployeeCourse>
  const { gotoPage, setPageSize, pageCount } = table
  const { pageIndex, pageSize, sortBy } = table.state as UsePaginationState<IEmployeeCourse> &
    UseSortByState<IEmployeeCourse>

  const onFetchDataDebounced = useAsyncDebounce(onFetchData, 1000)

  useEffect(() => {
    onFetchDataDebounced({
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortBy: sortBy,
      search,
    })
  }, [pageIndex, pageSize, sortBy, search])

  const searchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }

  return (
    <div className={section}>
      <div className={sectionContent}>
        <div className={sectionContentHeader}>
          <div className={sectionSearch}>
            <SearchInput placeholder="Введите запрос для поиска" onChange={searchHandler} fullWidth={true} />
          </div>
        </div>
        <Table<IEmployeeCourse>
          tableInstance={table}
          loading={props.loadCourses}
          getCellClass={getCellClass}
          rowWrap="wrap"
        />
        <TablePaging
          gotoPage={gotoPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSizeOptions={pageSizeOptions}
          setLocalPageSize={setLocalPageSize}
          setPageSize={setPageSize}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (store: IAppState): IEmployeeTableStateProps => {
  return {
    dispatch: store.dispatch,
    loadCourses: store.manufacturer.subdivision.employee.loadCourses,
  }
}

export default connect<IEmployeeTableStateProps, unknown, IEmployeeTableOwnProps, IAppState>(mapStateToProps)(
  EmployeeCourseTable
)
