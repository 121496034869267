import { withPrefix } from 'gatsby'
import React, { ReactElement, useEffect } from 'react'
import nextId from 'react-id-generator'
import { connect } from 'react-redux'

import Breadcrumbs, { IBreadcrumbs } from '../../../../shared/components/Breadcrumbs'
import LoaderSpinner from '../../../../shared/components/LoaderSpinner'
import PageInfoHeader from '../../../../shared/components/PageInfoHeader'
import PageTitle from '../../../../shared/components/PageTitle'
import { IEmployee } from '../../../proto/models'
import { getEmployeeInfo, resetStateEmployee } from '../../../reducers/manufacturerReducer'
import { AppDispatch, IAppState } from '../../../reducers/rootReducer'

interface IEmployeeInfoOwnProps {
  path: string
  subdivisionId?: string
  employeeId?: string
  children: React.ReactNode
}

interface IEmployeeInfoStateProps {
  dispatch: AppDispatch
  info: IEmployee
  loadInfo: boolean
}

type EmployeeInfoProps = IEmployeeInfoStateProps & IEmployeeInfoOwnProps

const EmployeeInfo = (props: EmployeeInfoProps): ReactElement<EmployeeInfoProps> => {
  const getDataPageInfoHeader = () => {
    return [
      {
        id: nextId(),
        title: 'ФИО',
        value: props.info?.name,
      },
      {
        id: nextId(),
        title: 'Наименование подразделения',
        value: props.info?.subdivision,
      },
      {
        id: nextId(),
        title: 'Телефон',
        value: props.info?.phone,
      },
      {
        id: nextId(),
        title: 'Почта',
        value: props.info?.email,
      },
    ]
  }

  const getDataBreadcrumbs = (): IBreadcrumbs[] => {
    return [
      {
        title: 'Кабинет производителя',
        link: withPrefix(`manufacturer-office/organization`),
      },
      {
        title: 'Подразделение',
        link: withPrefix(`manufacturer-office/organization/subdivision/${props.subdivisionId}`),
      },
      {
        title: 'Информация о сотруднике',
        currSection: true,
      },
    ]
  }

  useEffect(() => {
    props.dispatch(getEmployeeInfo(props.employeeId))

    return () => {
      props.dispatch(resetStateEmployee())
    }
  }, [props.employeeId])

  return props.loadInfo ? (
    <LoaderSpinner />
  ) : (
    <>
      <Breadcrumbs list={getDataBreadcrumbs()} />
      <PageTitle title="Информация о сотруднике" />
      <PageInfoHeader data={getDataPageInfoHeader()} />
      {props.children}
    </>
  )
}

const mapStateToProps = (store: IAppState): IEmployeeInfoStateProps => {
  return {
    dispatch: store.dispatch,
    info: store.manufacturer.subdivision.employee.info,
    loadInfo: store.manufacturer.subdivision.employee.loadInfo,
  }
}

export default connect<IEmployeeInfoStateProps, unknown, IEmployeeInfoOwnProps, IAppState>(mapStateToProps)(
  EmployeeInfo
)
