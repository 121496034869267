import classNames from 'classnames'
import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import { Link, withPrefix } from 'gatsby'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import {
  Cell,
  Column,
  ColumnInterface,
  SortingRule,
  TableInstance,
  TableOptions,
  TableState,
  useAsyncDebounce,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationOptions,
  UsePaginationState,
  useSortBy,
  UseSortByOptions,
  UseSortByState,
  useTable,
  UseTableOptions,
} from 'react-table'
import DeleteButton from 'shared/components/Buttons/DeleteButton'
import TextButton from 'shared/components/Buttons/TextButton'
import AddIcon from 'shared/components/Icons/AddIcon'
import PreviewIcon from 'shared/components/Icons/PreviewIcon'
import AlertObjectRemove from 'shared/components/Modal/AlertObjectRemove'
import SearchInput from 'shared/components/SearchInput'
import Table from 'shared/components/Table'
import {
  tableButtons,
  tableButtonsPreview,
  tableCell,
  tableText,
  tableTextContainer,
} from 'shared/components/Table/Table.module.scss'
import TablePaging from 'shared/components/TablePaging/TablePaging'

import {
  IManufacturerSubdivisionStaff,
  IManufacturerSubdivisionStaffList,
  IStaffAddRequest,
  IStaffToAddList,
} from '../../../proto/models'
import { IAddEmployeesRequest, IEmployeesRequest } from '../../../reducers/manufacturerReducer'
import AddStaffModal from './AddStaffModal'
import {
  section,
  sectionButtons,
  sectionContent,
  sectionHeader,
  sectionSearch,
  tableCellButtons,
  tableCellEmail,
  tableCellFullname,
  tableCellPhone,
} from './ManufacturerSubdivisionStaff.module.scss'

interface IManufacturerSubdivisionStaffProps {
  subdivisionId: string
  loadingEmployees: boolean
  loadingAddTable: boolean
  getEmployees: (options: IEmployeesRequest) => Promise<IManufacturerSubdivisionStaffList>
  deleteEmployee: (id: string) => void
  addEmployee: (options: IStaffAddRequest) => void
  getAddEmployees: (options: IAddEmployeesRequest) => Promise<IStaffToAddList>
  setEmployeeLoading: (value: boolean) => void
}

const ManufacturerSubdivisionStaffTable = (
  props: IManufacturerSubdivisionStaffProps
): ReactElement<IManufacturerSubdivisionStaffProps> => {
  const [fetchedData, setFetchedData] = useState<IManufacturerSubdivisionStaff[]>([])
  const [totalRowCount, setTotalRowCount] = useState(0)
  const [search, setSearch] = useState('')
  const [open, setOpen] = useState(false)
  const [openRemoveModal, setOpenRemoveModal] = useState(false)
  const [currId, setCurrId] = useState(null)

  const removeModalHandler = (id?: string) => {
    setOpenRemoveModal(true)
    setCurrId(id)
  }

  const modalCloseHandler = () => {
    setOpen(false)
  }

  const agreeRemoveCallback = async () => {
    props.deleteEmployee(currId)
    onFetchDataDebounced({
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortBy: sortBy,
      search,
    })
  }

  const addEmployeeCallback = (options: IStaffAddRequest) => {
    props.addEmployee(options)
    onFetchDataDebounced({
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortBy: sortBy,
      search,
    })
  }

  interface IFetchDataProps {
    pageIndex: number
    pageSize: number
    sortBy: Array<SortingRule<IManufacturerSubdivisionStaffList>>
    search: string
  }

  const onFetchData = async ({ pageIndex, pageSize, sortBy, search }: IFetchDataProps) => {
    const paging: IEmployeesRequest = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortColumn: sortBy[0]?.id || 'surname',
      sortDirection: sortBy[0]?.desc ?? true ? 'desc' : 'asc',
      subdivisionId: props.subdivisionId,
      searchQuery: search,
    }

    props.setEmployeeLoading(true)

    const response = await props.getEmployees(paging)
    setFetchedData(response?.items ?? [])
    setTotalRowCount(response?.rowCount ?? 0)
  }

  const getCellClass = (column: ColumnInterface<IManufacturerSubdivisionStaffList>) => {
    switch (column.id) {
      case 'surname': {
        return classNames(tableCell, tableCellFullname)
      }
      case 'phoneNumber': {
        return classNames(tableCell, tableCellPhone)
      }
      case 'email': {
        return classNames(tableCell, tableCellEmail)
      }
      case 'rowButtons': {
        return classNames(tableCell, tableCellButtons)
      }
    }
  }

  const columns: Column<IManufacturerSubdivisionStaff>[] = useMemo(
    () => [
      {
        Header: 'ФИО',
        id: 'surname',
        accessor: 'surname',
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: (cell: Cell<IManufacturerSubdivisionStaff>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText}>
                {cell.row.original.surname} {cell.row.original.name} {cell.row.original.middlename}
              </span>
            </p>
          )
        },
      },
      {
        Header: 'Телефон',
        id: 'phoneNumber',
        accessor: 'phoneNumber',
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: (cell: Cell<IManufacturerSubdivisionStaff>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText}>{cell.row.original.phoneNumber}</span>
            </p>
          )
        },
      },
      {
        Header: 'Почта',
        id: 'email',
        accessor: 'email',
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: (cell: Cell<IManufacturerSubdivisionStaff>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText}>{cell.row.original.email}</span>
            </p>
          )
        },
      },
      {
        id: 'rowButtons',
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: (cell: Cell<IManufacturerSubdivisionStaff>) => (
          <div className={tableButtons}>
            <Link
              className={tableButtonsPreview}
              to={withPrefix(
                `manufacturer-office/organization/subdivision/${props.subdivisionId}/employee/${GuidHelper.toString(
                  cell.row.original.userId
                )}/courses`
              )}
            >
              <PreviewIcon />
            </Link>
            <DeleteButton
              iconColor="blue"
              onClick={() => removeModalHandler(GuidHelper.toString(cell.row.original.id))}
            />
          </div>
        ),
      },
    ],
    []
  )

  const pageSizeOptions = [25, 50, 75]

  const [localPageSize, setLocalPageSize] = useState(pageSizeOptions[0])

  const data = useMemo(() => {
    return fetchedData
  }, [fetchedData])

  const tablePageCount = useMemo(
    () => Math.ceil(totalRowCount / (localPageSize || pageSizeOptions[0])),
    [totalRowCount, localPageSize]
  )

  const initialState: Partial<TableState<IManufacturerSubdivisionStaff>> &
    Partial<UsePaginationState<IManufacturerSubdivisionStaff>> &
    UseSortByState<IManufacturerSubdivisionStaff> = {
    pageSize: localPageSize,
    sortBy: [{ id: 'surname', desc: true }],
  }

  const tableOptions: TableOptions<IManufacturerSubdivisionStaff> &
    UseSortByOptions<IManufacturerSubdivisionStaff> &
    UsePaginationOptions<IManufacturerSubdivisionStaff> &
    UseTableOptions<IManufacturerSubdivisionStaff> = {
    data: data,
    columns: columns,
    pageCount: tablePageCount,
    initialState: initialState,
    disableSortRemove: true,
    manualSortBy: true,
    manualPagination: true,
  }

  const table = useTable<IManufacturerSubdivisionStaff>(
    tableOptions,
    useSortBy,
    usePagination
  ) as TableInstance<IManufacturerSubdivisionStaff> & UsePaginationInstanceProps<IManufacturerSubdivisionStaff>
  const { gotoPage, setPageSize, pageCount } = table
  const { pageIndex, pageSize, sortBy } = table.state as UsePaginationState<IManufacturerSubdivisionStaff> &
    UseSortByState<IManufacturerSubdivisionStaff>

  const onFetchDataDebounced = useAsyncDebounce(onFetchData, 1000)

  useEffect(() => {
    onFetchDataDebounced({
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortBy: sortBy,
      search,
    })
  }, [pageIndex, sortBy, pageSize, search])

  return (
    <>
      <div className={section}>
        <div className={sectionContent}>
          <div className={sectionHeader}>
            <div className={sectionButtons}>
              <TextButton
                text="Добавить сотрудника"
                iconLeft={<AddIcon color="white" />}
                onClick={() => {
                  setOpen(true)
                }}
              />
            </div>
            <div className={sectionSearch}>
              <SearchInput
                placeholder="Поиск по сотрудникам"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearch(e.target.value)
                }}
              />
            </div>
          </div>
          <Table<IManufacturerSubdivisionStaff>
            tableInstance={table}
            loading={props.loadingEmployees}
            getCellClass={getCellClass}
          />
          <TablePaging
            gotoPage={gotoPage}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSizeOptions={pageSizeOptions}
            setLocalPageSize={setLocalPageSize}
            setPageSize={setPageSize}
          />
        </div>
        <AlertObjectRemove
          isOpen={openRemoveModal}
          title="Вы уверены, что хотите удалить пользователя из списка сотрудников?"
          agreeString="Удалить"
          disagreeString="Отменить"
          agreeCallback={agreeRemoveCallback}
          handleClose={() => setOpenRemoveModal(false)}
        />
        <AddStaffModal
          open={open}
          subdivisionId={props.subdivisionId}
          addEmployee={addEmployeeCallback}
          handleClose={modalCloseHandler}
          getAddEmployees={props.getAddEmployees}
          loadingTable={props.loadingAddTable}
        />
      </div>
    </>
  )
}

export default ManufacturerSubdivisionStaffTable
