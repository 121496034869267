import { CSSProperties } from "react"

export const additionalChartStyle: CSSProperties = {
    height: '295px', overflowY: 'hidden'
}

export const seventyCountDataChartThreshold = 70

const thirtyFiveCountDataChartThreshold = 35

export const getChartWidth = (data: any): string => data.length > thirtyFiveCountDataChartThreshold ? '200%' : '99%'