import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import React from 'react'
import { connect } from 'react-redux'
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

import { ChartPivotRow, Filter, ResultSet } from '@cubejs-client/core'
import { QueryRendererRenderProps } from '@cubejs-client/react'

import CubeQueryRender, {
  IChartComponentProps,
  renderChart,
} from '../../../../../shared/components/QueryRender/CubeQueryRender'
import CustomTooltip from '../../../../../shared/components/Recharts/CustomTooltip'
import { IUserListResponse } from '../../../../../shared/proto/models'
import { IAppState } from '../../../../reducers/rootReducer'
import { blockChart, blockChartGraph, blockChartTitle, chart } from './BlockCharts.module.scss'
import { additionalChartStyle, seventyCountDataChartThreshold } from './utils'

const publicationCount = 'Course.publicationCount'
const courseAuthorId = 'Course.userId'
const courseId = 'Course.id'

const formatter = new Intl.NumberFormat('ru-RU', { maximumFractionDigits: 0, minimumFractionDigits: 0 })

const valueFormatter = (label: React.ReactText) => {
  return formatter.format(label.value as number)
}

const barSize = 80
const barSizeBigData = 10
const countBarStyle = 5

export const barСolor = '#FEC213'

const applyLabelToBars = (user: IUserListResponse, pivots: ChartPivotRow[]) => {
  const userId = GuidHelper.toString(user.userId).toLowerCase()
  const filteredPivots = pivots.filter((p) => p.xValues[0] === userId)
  const total = filteredPivots.length
  const withPublication = filteredPivots.filter((p) => p[publicationCount] !== 0).length
  const ratio = (withPublication / total) * 100

  const preparedData = {
    [courseAuthorId]: ratio,
    ['name']: user.fullName,
  }
  return preparedData
}

const getData = (set: ResultSet, users: IUserListResponse[]) => {
  const pivots = set.chartPivot({
    x: [courseAuthorId, courseId],
    y: [publicationCount],
  })
  const userIds = [...new Set(pivots.map((r) => r.xValues[0]))]

  const filteredUsers = users.filter((u) => userIds.includes(GuidHelper.toString(u.userId).toLowerCase()))

  const perparedData = filteredUsers.map((i) => applyLabelToBars(i, pivots))

  return perparedData
}

const barRender = (props: IBarRenderProps) => {
  const { resultSet, users } = props
  const data = getData(resultSet as ResultSet, users)
  const memoData = React.useMemo(() => data, [data])

  const width = data.length > seventyCountDataChartThreshold ? '200%' : '99%'

  return (
    <ResponsiveContainer width={width} height="100%">
      <BarChart data={data} margin={{ top: 20 }}>
        <XAxis hide={true} type="category" dataKey="name" />
        <YAxis key="YAxis" axisLine={false} tickLine={false} width={30} type="number" />
        <Tooltip content={<CustomTooltip getValue={valueFormatter} />} />
        <CartesianGrid strokeDasharray="5 5" />
        <Bar
          dataKey={courseAuthorId}
          stackId="a"
          fill={barСolor}
          minPointSize={3}
          barSize={memoData.length <= countBarStyle ? barSize : barSizeBigData}
        />
      </BarChart>
    </ResponsiveContainer>
  )
}

interface IBarRenderStateProps {
  users: IUserListResponse[]
}

type IBarRenderProps = IBarRenderStateProps & IChartComponentProps

const mapStateToBarChartProps = (store: IAppState): IBarRenderStateProps => {
  return {
    users: store.manufacturerBranchStatistics.users,
  }
}
const connectedBarRender = connect<IBarRenderStateProps, {}, IChartComponentProps, IAppState>(mapStateToBarChartProps)(
  barRender
)

const chartRender = (props: QueryRendererRenderProps) => (
  <div className={chart} style={additionalChartStyle}>
    {renderChart(connectedBarRender)(props)}
  </div>
)

const SuccessfulPublicationByAuthorChart = (props: SuccessfulPublicationByAuthorChartProps) => {
  return (
    <div className={blockChart}>
      <h2 className={blockChartTitle}>Процент успешной публикации курсов</h2>
      <div className={blockChartGraph}>
        <CubeQueryRender
          query={{
            measures: [publicationCount],
            dimensions: [courseAuthorId, courseId],
            filters: props.filters,
          }}
          render={chartRender}
        />
      </div>
    </div>
  )
}

interface ISuccessfulPublicationByAuthorChartStateProps {
  filters: Filter[]
}
type SuccessfulPublicationByAuthorChartProps = ISuccessfulPublicationByAuthorChartStateProps

const mapStateToProps = (store: IAppState): SuccessfulPublicationByAuthorChartProps => {
  return {
    filters: store.manufacturerBranchStatistics.successfulPublicationByAuthorChart,
  }
}

export default connect<SuccessfulPublicationByAuthorChartProps, unknown, unknown, IAppState>(mapStateToProps)(
  SuccessfulPublicationByAuthorChart
)
