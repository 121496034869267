import React, { ReactElement } from 'react'

import { Filter } from '@cubejs-client/core'
import { BlockAnswer, BlockSort, CoursePresentation } from 'shared/components/CourseStatistics'
import {
  getDateTimeRange,
  ICourseStatisticFiltersState,
} from 'shared/reducers/courseStatistics/courseStatisticFilterReducer'

import BlockCourseReport from './BlockReport/BlockCourseReport'
import DynamicsLearning from './Dynamics/DynamicsLearning'
import { sectionContent } from './CourseStatistics.module.scss'

export interface ICourseStatisticsOwnProps {
  path?: string
  courseId?: string
}

type CourseStatisticsProps = ICourseStatisticsOwnProps

const dynamicsLearningChart = (courseId: string, filter: ICourseStatisticFiltersState): Filter[] => {
  return [
    {
      dimension: 'StudentPublicationStatus.courseId',
      operator: 'equals',
      values: [courseId],
    },
    {
      dimension: 'PharmacistCourse.courseId',
      operator: 'equals',
      values: [courseId],
    },
    {
      dimension: 'StudentPublicationStatusHistory.createDate',
      operator: 'inDateRange',
      values: getDateTimeRange(filter),
    },
  ]
}

const dynamicsLearningTable = (courseId: string, filter: ICourseStatisticFiltersState): Filter[] => {
  return [
    {
      dimension: 'StudentPublicationStatus.courseId',
      operator: 'equals',
      values: [courseId],
    },
    {
      dimension: 'PharmacistCourse.courseId',
      operator: 'equals',
      values: [courseId],
    },
    {
      dimension: 'CourseTestSession.courseId',
      operator: 'equals',
      values: [courseId],
    },
    {
      dimension: 'CourseTestSession.createDate',
      operator: 'inDateRange',
      values: getDateTimeRange(filter),
    },
  ]
}

const presentationFilters = (courseId: string, filter: ICourseStatisticFiltersState): Filter[] => {
  return [
    {
      dimension: 'StudentPublicationStatus.courseId',
      operator: 'equals',
      values: [courseId],
    },
    {
      dimension: 'PharmacistCourse.courseId',
      operator: 'equals',
      values: [courseId],
    },
    {
      dimension: 'PresentationViewHistory.startDate',
      operator: 'inDateRange',
      values: getDateTimeRange(filter),
    },
  ]
}

const answerFilters = (courseId: string): Filter[] => {
  return [
    {
      dimension: 'PharmacistCourse.courseId',
      operator: 'equals',
      values: [courseId],
    },
    {
      dimension: 'TestQuestionStatistic.courseId',
      operator: 'equals',
      values: [courseId],
    },
  ]
}
const CourseStatistics = (props: CourseStatisticsProps): ReactElement<CourseStatisticsProps> => {
  return (
    <div className={sectionContent}>
      <BlockSort />
      <BlockCourseReport courseId={props.courseId} />
      <DynamicsLearning
        courseId={props.courseId}
        chartFilters={dynamicsLearningChart}
        tableFilters={dynamicsLearningTable}
      />
      <CoursePresentation courseId={props.courseId} getFilters={presentationFilters} />
      <BlockAnswer courseId={props.courseId} getFilters={answerFilters} />
    </div>
  )
}

export default CourseStatistics
