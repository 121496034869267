// extracted by mini-css-extract-plugin
export var section = "ManufacturerSubdivisionStaff-module--section--3FIOq";
export var sectionContent = "ManufacturerSubdivisionStaff-module--section-content--31fzW";
export var modal = "ManufacturerSubdivisionStaff-module--modal--3c1fb";
export var modalTitle = "ManufacturerSubdivisionStaff-module--modal-title--2APVh";
export var sectionHeader = "ManufacturerSubdivisionStaff-module--section-header--1x0BD";
export var sectionButtons = "ManufacturerSubdivisionStaff-module--section-buttons--YmknJ";
export var sectionSearch = "ManufacturerSubdivisionStaff-module--section-search--1zepe";
export var tableCellFullname = "ManufacturerSubdivisionStaff-module--table-cell-fullname--1iG6q";
export var tableCellPhone = "ManufacturerSubdivisionStaff-module--table-cell-phone--NZOLj";
export var tableCellEmail = "ManufacturerSubdivisionStaff-module--table-cell-email--2-w7z";
export var tableCellButtons = "ManufacturerSubdivisionStaff-module--table-cell-buttons--2fU_x";
export var modalHeader = "ManufacturerSubdivisionStaff-module--modal-header--3egxX";
export var modalHeaderRemove = "ManufacturerSubdivisionStaff-module--modal-header-remove--34B2j";
export var modalClose = "ManufacturerSubdivisionStaff-module--modal-close--2MPNQ";
export var modalBlockSearch = "ManufacturerSubdivisionStaff-module--modal-block-search--3v-PT";
export var modalBlockButtons = "ManufacturerSubdivisionStaff-module--modal-block-buttons--17VVS";
export var modalButton = "ManufacturerSubdivisionStaff-module--modal-button--hfwrz";
export var modalTable = "ManufacturerSubdivisionStaff-module--modal-table--2jpEe";
export var modalTableCellCheckbox = "ManufacturerSubdivisionStaff-module--modal-table-cell-checkbox--2R31f";
export var modalTableCellName = "ManufacturerSubdivisionStaff-module--modal-table-cell-name--TdAPc";
export var modalTableCellPhoneNumber = "ManufacturerSubdivisionStaff-module--modal-table-cell-phoneNumber--1SYJr";
export var modalTableCellEmail = "ManufacturerSubdivisionStaff-module--modal-table-cell-email--GH6II";