// extracted by mini-css-extract-plugin
export var modalClose = "ManufacturerSubdivision-module--modal-close--2T96v";
export var section = "ManufacturerSubdivision-module--section--3t58u";
export var sectionContent = "ManufacturerSubdivision-module--section-content--1Vdcx";
export var sectionContentHeader = "ManufacturerSubdivision-module--section-content-header--1bxw_";
export var sectionButtons = "ManufacturerSubdivision-module--section-buttons--3RArK";
export var tableCellName = "ManufacturerSubdivision-module--table-cell-name--ZQqp-";
export var tableCellMemberCount = "ManufacturerSubdivision-module--table-cell-memberCount--2sjUZ";
export var tableCellButtons = "ManufacturerSubdivision-module--table-cell-buttons--uwntP";
export var branch = "ManufacturerSubdivision-module--branch--2I0bo";
export var branchSecondary = "ManufacturerSubdivision-module--branch-secondary--2TKgN";
export var branchIcon = "ManufacturerSubdivision-module--branch-icon--G8u1R";
export var modal = "ManufacturerSubdivision-module--modal--2e8jm";
export var modalHeader = "ManufacturerSubdivision-module--modal-header--NpLGw";
export var modalTitle = "ManufacturerSubdivision-module--modal-title--1_qim";
export var modalForm = "ManufacturerSubdivision-module--modal-form--20zL9";
export var modalBlockSearch = "ManufacturerSubdivision-module--modal-block-search--7Bg0l";
export var modalBlockButtons = "ManufacturerSubdivision-module--modal-block-buttons--2_OAD";
export var modalBlockSelect = "ManufacturerSubdivision-module--modal-block-select--27Ybc";
export var modalBlockSelectWrraper = "ManufacturerSubdivision-module--modal-block-select-wrraper--rhdEO";
export var modalSelect = "ManufacturerSubdivision-module--modal-select--3ZTQ9";
export var modalButton = "ManufacturerSubdivision-module--modal-button--3VpAM";