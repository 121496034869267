import { ManufacturerOffice } from 'manufacturer-office'
import React, { ReactElement } from 'react'

const Main = (): ReactElement => (
  <>
    <ManufacturerOffice />
  </>
)

export default Main
