import { navigate, withPrefix } from 'gatsby'
import { rootReducer } from 'manufacturer-office/reducers/rootReducer'
import React from 'react'
import { Provider } from 'react-redux'
import CourseDesigner from 'shared/components/CourseDesigner'
import CourseEditor from 'shared/components/CourseDesigner/CourseEditor'
import CourseCreatePage from 'shared/components/CourseDesigner/CourseEditPage/CourseCreatePage'
import CourseEditPage from 'shared/components/CourseDesigner/CourseEditPage/CourseEditPage'
import CourseSettings from 'shared/components/CourseDesigner/CourseSettings'
import Presentation from 'shared/components/CourseDesigner/Presentation'
import Publications from 'shared/components/CourseDesigner/Publications'
import CreatePublication from 'shared/components/CourseDesigner/Publications/CreatePublication/CreatePublication'
import TestEditor from 'shared/components/CourseDesigner/TestEditor'
import { Error404, Error500 } from 'shared/components/Errors'

import { Redirect, Router } from '@reach/router'
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'

import AdvancedTargetingForm from '../CourseDesigner/AdvancedTargeting/AdvancedTargetingForm'
import CourseInfoPage from '../CourseInfo/CourseInfoPage'
import EmployeeCourseInfo from '../CourseInfo/EmployeeCourseInfo'
import CourseReviewsPage from '../CourseReviews/CourseReviewsPage'
import CourseStatistics from '../CourseStatistics'
import Employee from '../ManufacturerOffices/Employee/Employee'
import EmployeeCourseTable from '../ManufacturerOffices/Employee/EmployeeCourses'
import ManufacturerAccount from '../ManufacturerOffices/ManufacturerAccounts/ManufacturerAccount'
import ManufacturerInfo from '../ManufacturerOffices/ManufacturerInfo'
import ManufacturerSubdivision from '../ManufacturerOffices/ManufacturerSubdivisions/ManufacturerSubdivision'
import ManufacturerSubdivisionStaff from '../ManufacturerOffices/ManufacturerSubdivisionStaff/ManufacturerSubdivisionStaff'
import PersonalProfilePage from '../PersonalProfile'
import AppWrapper from './AppWrapper'
import PrivateRoute from 'shared/components/PrivateRoute/PrivateRoute'
import { AuthError, ThrottleError } from 'external/rp.ui/utils/protoClient'
import Statistics from '../Statistics'
import GeneralStatistics from '../Statistics/GeneralStatistics'
import CourseStatisticsTable from '../Statistics/CourseStatisticsTable'
import StatisticsCourseInfo from '../CourseInfo/StatisticsCourseInfo'
import { ETeqServices } from 'shared/proto/models'

const middleware = getDefaultMiddleware({ immutableCheck: false, serializableCheck: false, thunk: true })

const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: process.env.NODE_ENV === 'development',
})

export type ManufacturerOfficeRootState = ReturnType<typeof store.getState>
export type ManufacturerOfficeAppDispatch = typeof store.dispatch

const App: React.FC = () => {
  React.useEffect(() => {
    window.onunhandledrejection = (ev: PromiseRejectionEvent) => {
      if (ev.reason instanceof ThrottleError) {
        return
      }

      if (ev.reason instanceof AuthError) {
        ev.preventDefault()
        navigate(`/`)
        return
      }

      navigate('/manufacturer-office/error', { replace: true })
    }

    return () => {
      window.onunhandledrejection = null
    }
  })

  return (
    <Provider store={store}>
      <Router>
        <Redirect
          noThrow={true}
          from={withPrefix('/manufacturer-office')}
          to={withPrefix('/manufacturer-office/course-designer')}
        />
        <PrivateRoute path={withPrefix('/manufacturer-office')} component={AppWrapper}>
          <Error500 path="/error" />
          <Error404 default />
          <PersonalProfilePage path="/profile" />
          <CourseDesigner
            path="/course-designer"
            localPrefix="manufacturer-office"
            service={ETeqServices.ManufacturerOffice}
          />
          <CourseEditPage path="/course-designer/course/:courseId/edit" localPrefix="manufacturer-office" />
          <CourseCreatePage path="/course-designer/create" localPrefix="manufacturer-office" />
          <CourseEditor path="/course-designer/course/:courseId" localPrefix="manufacturer-office">
            <TestEditor path="test-editor" localPrefix="manufacturer-office" />
            <CourseSettings path="settings" localPrefix="manufacturer-office" />
            <Presentation path="presentation" localPrefix="manufacturer-office" />
            <Publications path="publications" localPrefix="manufacturer-office" />
          </CourseEditor>
          <CreatePublication
            path="/course-designer/course/:courseId/publications/:publicationId"
            TargetingFilterForm={AdvancedTargetingForm}
            localPrefix="manufacturer-office"
            service={ETeqServices.ManufacturerOffice}
          />
          <ManufacturerSubdivisionStaff path="/organization/subdivision/:id" />
          <Employee path="/organization/subdivision/:subdivisionId/employee/:employeeId">
            <EmployeeCourseTable path="courses" />
          </Employee>
          <ManufacturerInfo path="/organization">
            <ManufacturerSubdivision path="/" />
            <ManufacturerAccount path="manufacturer-account" />
          </ManufacturerInfo>
          <Statistics path="statistics">
            <GeneralStatistics path="/" />
            <CourseStatisticsTable path="/courses" />
          </Statistics>
          <StatisticsCourseInfo path="/statistics/courses/:courseId">
            <CourseInfoPage path="info" />
            <CourseReviewsPage path="reviews" />
            <CourseStatistics path="statistics" />
          </StatisticsCourseInfo>
          <EmployeeCourseInfo path="/organization/subdivision/:subdivisionId/employee/:employeeId/course/:courseId">
            <CourseInfoPage path="info" />
            <CourseReviewsPage path="reviews" />
            <CourseStatistics path="statistics" />
          </EmployeeCourseInfo>
        </PrivateRoute>
      </Router>
    </Provider>
  )
}

export default App
