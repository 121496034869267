import { Action, combineReducers } from 'redux'
import { FormStateMap, reducer as formReducer } from 'redux-form'
import { ThunkDispatch } from 'redux-thunk'
import applicationReducer, { IApplicationState } from 'shared/reducers/application/applicationReducer'
import { courseDesignerReducers, ICourseDesignerReducersState } from 'shared/reducers/CourseDesigner'
import permissionsReducer, { IPermissionsState } from 'shared/reducers/CourseDesigner/permissionsReducer'
import courseInfoReducer, { ICourseInfoState } from 'shared/reducers/courseInfo/courseInfoReducer'
import courseReviewReducer, { ICourseReviewState } from 'shared/reducers/courseInfo/courseReviewReducer'
import { courseStatisticRootReducer, CourseStatisticsFilterState } from 'shared/reducers/courseStatistics'
import menuItemsReducer, { IMenuItemsState } from 'shared/reducers/menuItems/menuItemsReducer'
import organizationCourseReducer, {
  OrganizationCourseState,
} from 'shared/reducers/organizationCourses/organizationCourseReducer'
import personalProfileReducer, { IPersonalProfileState } from 'shared/reducers/personalProfile/personalProfileReducer'

import { createAction } from '@reduxjs/toolkit'

import provisorExperienceReducer, {
  IProvisorExperienceState,
} from '../../shared/reducers/provisorExperience/provisorExperienceReducer'
import manufacturerBranchStatisticsReducer, {
  IManufacturerBranchStatisticFiltersState,
} from './manufacturerBranchStatisticsReducer'
import manufacturerReducer, { IManufacturerState } from './manufacturerReducer'
import alertReducer, { IAlertInitialState } from 'shared/reducers/alert/alertReducer'

export interface IAppState {
  dispatch: AppDispatch
  form: FormStateMap
  manufacturer: IManufacturerState
  courseInfo: ICourseInfoState
  courseStatisticFilters: CourseStatisticsFilterState
  application: IApplicationState
  courseReview: ICourseReviewState
  courseDesigner: ICourseDesignerReducersState
  menuItems: IMenuItemsState
  profile: IPersonalProfileState
  provisorExperience: IProvisorExperienceState
  manufacturerBranchStatistics: IManufacturerBranchStatisticFiltersState
  organizationCourses: OrganizationCourseState
  permissions: IPermissionsState
  alert: IAlertInitialState
}

export type AppDispatch = ThunkDispatch<IAppState, unknown, Action>

const appReducer = combineReducers({
  form: formReducer,
  manufacturer: manufacturerReducer,
  courseInfo: courseInfoReducer,
  courseStatisticFilters: courseStatisticRootReducer,
  application: applicationReducer,
  courseReview: courseReviewReducer,
  courseDesigner: courseDesignerReducers,
  menuItems: menuItemsReducer,
  profile: personalProfileReducer,
  provisorExperience: provisorExperienceReducer,
  manufacturerBranchStatistics: manufacturerBranchStatisticsReducer,
  organizationCourses: organizationCourseReducer,
  permissions: permissionsReducer,
  alert: alertReducer,
})

export const resetAppState = createAction('RESET_STATE')

export const rootReducer = (state, action) => {
  if (action.type === 'RESET_STATE') {
    state = undefined
  }
  return appReducer(state, action)
}
