// extracted by mini-css-extract-plugin
export var forminfoSubtitle = "AdvancedTargetingForm-module--forminfo-subtitle--7pIWo";
export var section = "AdvancedTargetingForm-module--section--2Jq9b";
export var sectionButtons = "AdvancedTargetingForm-module--section-buttons--iwJf4";
export var sectionButton = "AdvancedTargetingForm-module--section-button--2uRKX";
export var forminfo = "AdvancedTargetingForm-module--forminfo--3MfVX";
export var forminfoClose = "AdvancedTargetingForm-module--forminfo-close--3cW9e";
export var forminfoCloseButton = "AdvancedTargetingForm-module--forminfo-close-button--3Vag4";
export var forminfoList = "AdvancedTargetingForm-module--forminfo-list--3FX2t";
export var forminfoItem = "AdvancedTargetingForm-module--forminfo-item--Eb5Dl";
export var forminfoItemWrapper = "AdvancedTargetingForm-module--forminfo-item-wrapper--12dKu";
export var forminfoBlockSelect = "AdvancedTargetingForm-module--forminfo-block-select--1cFwK";
export var forminfoBlockSearch = "AdvancedTargetingForm-module--forminfo-block-search--1z4Yk";
export var forminfoTitle = "AdvancedTargetingForm-module--forminfo-title--qLEo2";
export var forminfoSettings = "AdvancedTargetingForm-module--forminfo-settings--izwPw";
export var forminfoResult = "AdvancedTargetingForm-module--forminfo-result--2eZbT";