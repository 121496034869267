import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import throttleFetch from 'external/rp.ui/utils/throttleFetch'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { change, DecoratedFormProps, initialize, SubmissionError } from 'redux-form'
import TextButton from 'shared/components/Buttons/TextButton'
import AddIcon from 'shared/components/Icons/AddIcon'

import {
  IManufacturerSubdivisionSearch,
  IUserManufacturer,
  ManufacturerSubdivision as ManufacturerSubdivisionType,
} from '../../../proto/models'
import {
  ISubdivisionFormValues,
  resetSearchSubdivisionState,
  resetStateSubdivision,
  saveOrUpdateSubdivisions,
  searchSubdivision,
  setLoadTableSubdivision,
} from '../../../reducers/manufacturerReducer'
import { AppDispatch, IAppState } from '../../../reducers/rootReducer'
import { section, sectionButtons, sectionContent, sectionContentHeader } from './ManufacturerSubdivision.module.scss'
import ManufacturerSubdivisionEditModal, {
  IManufacturerSubdivisionEditModalContext,
} from './ManufacturerSubdivisionEditModal'
import ManufacturerSubdivisionTable from './ManufacturerSubdivisionTable'

const FORM_NAME = 'manufacturerSubdivisionEditorForm'

type modeId = 'create' | 'edit'

export interface IModalOptions {
  title: string
  submitButtonText: string
  id: modeId
}

export type IModalMode = {
  create: IModalOptions
  edit: IModalOptions
}

const modalOptions: IModalMode = {
  create: {
    title: 'Создание подразделения',
    submitButtonText: 'Создать',
    id: 'create',
  },
  edit: {
    title: 'Редактирование подразделения',
    submitButtonText: 'Сохранить',
    id: 'edit',
  },
}

interface IManufacturerSubdivisionStateProps {
  info: IUserManufacturer
  dispatch: AppDispatch
  loadTableSubdivision: boolean
  searchSubdivision: IManufacturerSubdivisionSearch[]
}

interface IManufacturerSubdivisionOwnProps {
  path: string
}

type ManufacturerSubdivisionProps = IManufacturerSubdivisionOwnProps & IManufacturerSubdivisionStateProps

const initialFormValues: ISubdivisionFormValues = {
  id: null,
  manufacturerSubdivisionName: null,
  parentManufacturerSubdivision: null,
}

const saveOrUpdate = throttleFetch(
  async (
    values: ISubdivisionFormValues,
    dispatch: AppDispatch,
    props: DecoratedFormProps<ISubdivisionFormValues, IManufacturerSubdivisionEditModalContext, string>
  ) => {
    if (props.valid && !props.submitting) {
      const response = await dispatch(saveOrUpdateSubdivisions(values, values.id?.length > 0))

      if (!response.success) {
        throw new SubmissionError({
          manufacturerSubdivisionName: 'Запись с таким наименованием уже существует.',
        })
      } else {
        dispatch(setLoadTableSubdivision(true))
      }
    }
  }
)

const ManufacturerSubdivision = (props: ManufacturerSubdivisionProps) => {
  const [open, setOpen] = useState(false)
  const [modalOption, setModalOption] = useState<IModalOptions>(null)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    props.dispatch(initialize(FORM_NAME, initialFormValues))
    props.dispatch(resetSearchSubdivisionState())
  }

  const editButtonClickHandler = (data: ISubdivisionFormValues) => {
    setModalOption(modalOptions.edit)
    props.dispatch(initialize(FORM_NAME, data))
    handleOpen()
  }

  const createButtonClickHandler = () => {
    setModalOption(modalOptions.create)
    handleOpen()
  }

  const submitHandler = async (
    values: ISubdivisionFormValues,
    dispatch: AppDispatch,
    props: DecoratedFormProps<ISubdivisionFormValues, IManufacturerSubdivisionEditModalContext, string>
  ) => {
    await saveOrUpdate(values, dispatch, props)
    handleClose()
  }

  const clearButtonClickHandler = (name: string) => {
    props.dispatch(change(FORM_NAME, name, initialFormValues.parentManufacturerSubdivision))
  }

  const searchSubdivisionHandler = (searchString: string) => {
    props.dispatch(searchSubdivision(searchString, GuidHelper.toString(props.info.id)))
  }

  useEffect(() => {
    return () => {
      setOpen(false)
      setModalOption(null)
      props.dispatch(resetStateSubdivision())
    }
  }, [])

  return (
    <>
      <div className={section}>
        <div className={sectionContent}>
          <div className={sectionContentHeader}>
            <div className={sectionButtons}>
              <TextButton
                iconLeft={<AddIcon color="white" />}
                text="Создать подразделение"
                onClick={createButtonClickHandler}
              />
            </div>
          </div>
          <ManufacturerSubdivisionTable
            dispatch={props.dispatch}
            loadTable={props.loadTableSubdivision}
            onEditButton={editButtonClickHandler}
          />
          <ManufacturerSubdivisionEditModal
            open={open}
            handleClose={handleClose}
            onSubmit={submitHandler}
            options={modalOption}
            onClearButton={clearButtonClickHandler}
            onSearchSubdivision={searchSubdivisionHandler}
            searchSubdivision={props.searchSubdivision}
          />
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (store: IAppState): IManufacturerSubdivisionStateProps => {
  return {
    dispatch: store.dispatch,
    info: store.manufacturer.info,
    loadTableSubdivision: store.manufacturer.subdivision.loadTable,
    searchSubdivision: store.manufacturer.subdivision.search,
  }
}

export default connect<IManufacturerSubdivisionStateProps, unknown, IManufacturerSubdivisionOwnProps, IAppState>(
  mapStateToProps
)(ManufacturerSubdivision)
