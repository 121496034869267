import React from 'react'
import { connect } from 'react-redux'
import { info } from 'shared/components/CourseStatistics/CourseStatisticsInfo/BlockCharts.module.scss'
import Details, { IDetailsItemProps } from 'shared/components/CourseStatistics/Details/Details'
import CubeQueryRender, {
  IChartComponentProps,
  ICubePivotConfig,
  renderChart,
} from 'shared/components/QueryRender/CubeQueryRender'
import { EPublicationStatus } from 'shared/proto/models'
import { AppDispatch, CourseStatisticsFilterState } from 'shared/reducers/courseStatistics'
import {
  getDateTimeRange,
  ICourseStatisticFiltersState,
} from 'shared/reducers/courseStatistics/courseStatisticFilterReducer'

import { ResultSet } from '@cubejs-client/core'

const percentage = (dividend: number, divider: number): string => {
  if (!divider || divider === 0) {
    return '0.0%'
  }
  return `${(((dividend ?? 0) * 100) / (divider ?? 1)).toFixed(2)}%`
}

const tableRender = ({ resultSet }: IChartComponentProps) => {
  resultSet = resultSet as { [key: string]: ResultSet }
  const pivotConfig: ICubePivotConfig = {
    x: [],
    y: [],
    fillMissingDates: true,
    joinDateRange: false,
  }
  const pharmacist = resultSet.Pharmacist.tablePivot(pivotConfig)[0]
  const status = resultSet.StudentPublicationStatus.tablePivot(pivotConfig)[0]
  const feedbacks = resultSet.Feedbacks.tablePivot(pivotConfig)[0]
  const timedOut = resultSet[timedOutKey].tablePivot(pivotConfig)[0]
  const inProgress = resultSet.InProgress.tablePivot(pivotConfig)[0]

  const dataDetails: IDetailsItemProps[] = [
    {
      title: 'Назначено:',
      value: pharmacist['PharmacistCourse.count'].toString(),
    },
    {
      title: 'Взаимодействовали:',
      value: status['StudentPublicationStatus.count'].toString(),
    },
    {
      title: 'Изучили:',
      value: percentage(+inProgress['StudentPublicationStatus.countInProgress'], +pharmacist['PharmacistCourse.count']),
    },
    {
      title: 'Общая оценка:',
      value: (+feedbacks['CourseFeedback.averageGrade'])?.toFixed(2) ?? '0',
    },
    {
      title: 'Сдали курс:',
      value: percentage(+status['StudentPublicationStatus.countPassed'], +pharmacist['PharmacistCourse.count']),
    },
    {
      title: 'Не сдали курс:',
      value: percentage(
        +status['StudentPublicationStatus.countFailed'] + +timedOut[timedOutCount],
        +pharmacist['PharmacistCourse.count']
      ),
    },
  ]
  return (
    <div className={info}>
      <Details array={dataDetails} />
    </div>
  )
}

const timedOutKey = 'TimedOutCourseAttemptByOrganization'
const timedOutCount = 'TimedOutCourseAttemptByOrganization.count'

const BlockCourseReport = (props: BlockCourseReportProps) => {
  const { courseId, filter } = props
  return (
    <CubeQueryRender
      queries={{
        Feedbacks: {
          measures: ['CourseFeedback.averageGrade'],
          filters: [
            {
              dimension: 'Course.id',
              operator: 'equals',
              values: [courseId],
            },
            {
              dimension: 'CourseFeedback.date',
              operator: 'inDateRange',
              values: getDateTimeRange(filter),
            },
          ],
        },
        Pharmacist: {
          measures: ['PharmacistCourse.count'],
          filters: [
            {
              dimension: 'PharmacistCourse.courseId',
              operator: 'equals',
              values: [courseId],
            },
            {
              dimension: 'PublicationHistory.createDate',
              operator: 'inDateRange',
              values: getDateTimeRange(filter),
            },
            {
              dimension: 'PublicationHistory.status',
              operator: 'equals',
              values: [`${EPublicationStatus.Active}`],
            },
          ],
        },
        InProgress: {
          measures: ['StudentPublicationStatus.countInProgress'],
          filters: [
            {
              dimension: 'StudentPublicationStatus.courseId',
              operator: 'equals',
              values: [courseId],
            },
            {
              dimension: 'StudentPublicationStatusHistory.createDate',
              operator: 'inDateRange',
              values: getDateTimeRange(filter),
            },
            {
              dimension: 'Publication.status',
              operator: 'equals',
              values: [EPublicationStatus.Active.toString()],
            },
          ],
        },
        StudentPublicationStatus: {
          measures: [
            'StudentPublicationStatus.count',
            'StudentPublicationStatus.countPassed',
            'StudentPublicationStatus.countFailed',
          ],
          filters: [
            {
              dimension: 'StudentPublicationStatus.courseId',
              operator: 'equals',
              values: [courseId],
            },
            {
              dimension: 'Course.id',
              operator: 'equals',
              values: [courseId],
            },
            {
              dimension: 'StudentPublicationStatusHistory.createDate',
              operator: 'inDateRange',
              values: getDateTimeRange(filter),
            },
          ],
        },
        [timedOutKey]: {
          measures: [timedOutCount],
          filters: [
            {
              dimension: 'TimedOutCourseAttemptByOrganization.courseId',
              operator: 'equals',
              values: [props.courseId],
            },
            {
              dimension: 'TimedOutCourseAttemptByOrganization.courseEndDate',
              operator: 'inDateRange',
              values: getDateTimeRange(filter),
            },
          ],
        },
      }}
      render={renderChart(tableRender)}
    />
  )
}

export interface IBlockCourseReportOwnProps {
  courseId: string
}

interface IBlockCourseReportStateProps {
  dispatch: AppDispatch
  filter: ICourseStatisticFiltersState
}

type BlockCourseReportProps = IBlockCourseReportOwnProps & IBlockCourseReportStateProps

const mapStateToProps = (store: CourseStatisticsFilterState): IBlockCourseReportStateProps => {
  return {
    dispatch: store.dispatch,
    filter: store.courseStatisticFilters.filter,
  }
}

export default connect<IBlockCourseReportStateProps, unknown, IBlockCourseReportOwnProps, CourseStatisticsFilterState>(
  mapStateToProps
)(BlockCourseReport)
