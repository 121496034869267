/* eslint-disable react/display-name */
/* eslint-disable react/jsx-key */
import classNames from 'classnames'
import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import React, { MutableRefObject, ReactElement, useEffect, useState } from 'react'
import {
  Cell,
  Column,
  ColumnInterface,
  Row,
  TableInstance,
  TableOptions,
  TableToggleRowsSelectedProps,
  useAsyncDebounce,
  useRowSelect,
  UseRowSelectInstanceProps,
  UseRowSelectRowProps,
  useTable,
  UseTableOptions,
} from 'react-table'
import TextButton from 'shared/components/Buttons/TextButton'
import CustomCheckbox from 'shared/components/CustomCheckbox/CustomCheckbox'
import AddIcon from 'shared/components/Icons/AddIcon'
import SearchInput from 'shared/components/SearchInput'
import Table from 'shared/components/Table'
import {
  tableCell,
  tableRow,
  tableRowChecked,
  tableText,
  tableTextContainer,
} from 'shared/components/Table/Table.module.scss'

import { IStaffAddRequest, IStaffToAdd, IStaffToAddList } from '../../../proto/models'
import { IAddEmployeesRequest } from '../../../reducers/manufacturerReducer'
import {
  modalBlockButtons,
  modalBlockSearch,
  modalButton,
  modalTable,
  modalTableCellCheckbox,
  modalTableCellEmail,
  modalTableCellName,
  modalTableCellPhoneNumber,
} from './ManufacturerSubdivisionStaff.module.scss'

interface IAddStaffTableProps {
  subdivisionId: string
  addClickButtonHandler: (parameters: IStaffAddRequest) => void
  getAddEmployees: (options: IAddEmployeesRequest) => Promise<IStaffToAddList>
  cancelClickButtonHandler: () => void
  loading: boolean
}

const AddStaffTable = (props: IAddStaffTableProps): ReactElement<IAddStaffTableProps> => {
  const [fetchedData, setFetchedData] = useState<IStaffToAdd[]>([])
  const [search, setSearch] = useState('')

  const getRowClass = (row: Row<IStaffToAdd>) => {
    const selectableRow = row as Row<IStaffToAdd> & UseRowSelectRowProps<IStaffToAdd>
    return classNames(tableRow, { [tableRowChecked]: selectableRow.isSelected })
  }

  const getCellClass = (column: ColumnInterface<IStaffToAdd>) => {
    switch (column.id) {
      case 'selection': {
        return classNames(tableCell, modalTableCellCheckbox)
      }
      case 'Name': {
        return classNames(tableCell, modalTableCellName)
      }
      case 'phoneNumber': {
        return classNames(tableCell, modalTableCellPhoneNumber)
      }
      case 'email': {
        return classNames(tableCell, modalTableCellEmail)
      }
    }
  }

  const onFetchData = async (search: string) => {
    const parameters = {
      searchString: search,
    }

    const list = await props.getAddEmployees(parameters)
    setFetchedData(list?.items ?? [])
  }

  const onUserAdd = async () => {
    const parameters: IStaffAddRequest = {
      userIds: selectedFlatRows.map((d) => d.original.id),
      subdivisionId: GuidHelper.parse(props.subdivisionId),
    }
    props.addClickButtonHandler(parameters)
  }

  const data = React.useMemo(() => {
    return fetchedData
  }, [fetchedData])

  const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, TableToggleRowsSelectedProps & { id: string }>(
    ({ indeterminate, id, ...rest }, ref) => {
      const defaultRef = React.useRef<HTMLInputElement>()
      const resolvedRef = (ref as MutableRefObject<HTMLInputElement>) || defaultRef

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])

      return <CustomCheckbox id={id} ref={resolvedRef} {...rest} />
    }
  )

  const columns: Column<IStaffToAdd>[] = React.useMemo(
    () => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }: UseRowSelectInstanceProps<IStaffToAdd>) => (
          <IndeterminateCheckbox id={`globalSelect`} {...getToggleAllRowsSelectedProps()} />
        ),

        Cell: ({ row }: { row: Row<IStaffToAdd> & UseRowSelectRowProps<IStaffToAdd> }) => (
          <IndeterminateCheckbox id={`item-${row.id}`} {...row.getToggleRowSelectedProps()} />
        ),
      },
      {
        Header: 'ФИО',
        id: 'Name',
        Cell: (cell: Cell<IStaffToAdd>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText}>
                {cell.row.original.surname} {cell.row.original.name} {cell.row.original.middlename}
              </span>
            </p>
          )
        },
      },
      {
        Header: 'Телефон',
        id: 'phoneNumber',
        Cell: (cell: Cell<IStaffToAdd>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText}>{cell.row.original.phoneNumber}</span>
            </p>
          )
        },
      },
      {
        Header: 'Почта',
        id: 'email',
        Cell: (cell: Cell<IStaffToAdd>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText}></span>
            </p>
          )
        },
      },
    ],
    []
  )

  const tableOptions: TableOptions<IStaffToAdd> & UseTableOptions<IStaffToAdd> = {
    data: data,
    columns: columns,
  }

  const table = useTable<IStaffToAdd>(tableOptions, useRowSelect) as TableInstance<IStaffToAdd> &
    UseRowSelectInstanceProps<IStaffToAdd>
  const { selectedFlatRows } = table

  const onFetchDataDebounced = useAsyncDebounce(onFetchData, 1000)

  useEffect(() => {
    onFetchDataDebounced(search)
  }, [search])

  return (
    <>
      <div className={modalBlockSearch}>
        <SearchInput
          placeholder="Введите запрос для поиска"
          fullWidth={true}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSearch(e.target.value)
          }}
        />
      </div>
      <div className={modalTable}>
        <Table<IStaffToAdd>
          tableInstance={table}
          getCellClass={getCellClass}
          getRowClass={getRowClass}
          addHover={true}
          rowWrap="nowrap"
          loading={props.loading}
        />
      </div>
      <div className={modalBlockButtons}>
        <div className={modalButton}>
          <TextButton text="Добавить" iconLeft={<AddIcon color="white" />} onClick={onUserAdd} />
        </div>
        <div className={modalButton}>
          <TextButton variant="outlined" text="Отменить" onClick={props.cancelClickButtonHandler} />
        </div>
      </div>
    </>
  )
}

export default AddStaffTable
