import React, { ReactElement, useEffect } from 'react'
import nextId from 'react-id-generator'
import { connect } from 'react-redux'
import LoaderSpinner from 'shared/components/LoaderSpinner'
import Notification from 'shared/components/Notification'
import PageInfoHeader from 'shared/components/PageInfoHeader/PageInfoHeader'
import PageTitle from 'shared/components/PageTitle'
import PersonalProfile from 'shared/components/PersonalProfile/PersonalProfile'
import { IProfileInfo } from 'shared/proto/models'
import { AppDispatch, IPersonalProfileAppState } from 'shared/reducers/personalProfile'
import { hideNotification } from 'shared/reducers/personalProfile/personalProfileReducer'

import { getInfo } from '../../reducers/manufacturerReducer'
import { IAppState } from '../../reducers/rootReducer'
import { section, sectionContent, sectionWrapper } from './PersonalProfilePage.module.scss'

const getDataPageInfoHeader = (name: string, phone: string, email: string) => {
  return [
    {
      id: nextId(),
      title: 'Наименование компании',
      value: name,
    },
    {
      id: nextId(),
      title: 'Телефон',
      value: phone,
    },
    {
      id: nextId(),
      title: 'Email',
      value: email,
    },
  ]
}

interface IPersonalProfilePageOwnProps {
  path: string
}

interface IPersonalProfilePageStateProps {
  dispatch: AppDispatch
  loading: boolean
  notificationOpen: boolean
  manufacturerName: string
  profileInfo: IProfileInfo
}

type PersonalProfilePageProps = IPersonalProfilePageOwnProps & IPersonalProfilePageStateProps

const PersonalProfilePage = (props: PersonalProfilePageProps): ReactElement<PersonalProfilePageProps> => {
  const { manufacturerName, profileInfo } = props

  useEffect(() => {
    if (!manufacturerName) {
      props.dispatch(getInfo())
    }
  }, [])

  return (
    <section className={section}>
      <PageTitle title="Личный профиль" />

      {manufacturerName && Object.keys(profileInfo).length !== 0 ? (
        <>
          <PageInfoHeader data={getDataPageInfoHeader(manufacturerName, profileInfo.phone, profileInfo.email)} />
          <div className={sectionContent}>
            <div className={sectionWrapper}>
              <Notification
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={props.notificationOpen}
                autoHideDuration={6000}
                text="Личные данные сохранены"
                onClose={() => props.dispatch(hideNotification())}
                response="positive"
              />
              <PersonalProfile />
            </div>
          </div>
        </>
      ) : (
        <LoaderSpinner />
      )}
    </section>
  )
}

const mapStateToProps = (store: IAppState): IPersonalProfilePageStateProps => {
  return {
    dispatch: store.dispatch,
    loading: store.profile.loading,
    notificationOpen: store.profile.notificationOpen,
    profileInfo: store.profile.profileInfo,
    manufacturerName: store.manufacturer.info?.shortName,
  }
}

export default connect<
  IPersonalProfilePageStateProps,
  unknown,
  IPersonalProfilePageOwnProps,
  IPersonalProfileAppState & IAppState
>(mapStateToProps)(PersonalProfilePage)
