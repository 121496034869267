import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import { useAppSelector } from 'manufacturer-office/components/App/hook'
import { IUserManufacturer } from 'manufacturer-office/proto/models'
import React, { ReactElement, useEffect } from 'react'
import { connect } from 'react-redux'
import PublicationByCategoryChart from 'shared/components/GeneralStatistic/PublicationByCategoryChart'

import LoaderSpinner from '../../../../shared/components/LoaderSpinner'
import { changeFilters, clearUsers, fetchUsers } from '../../../reducers/manufacturerBranchStatisticsReducer'
import { AppDispatch, IAppState } from '../../../reducers/rootReducer'
import CourseByAuthorChart from './Charts/CourseByAuthorChart'
import SuccessfulPublicationByAuthorChart from './Charts/SuccessfulPublicationByAuthorChart'
import TotalPassedCourseChart from './Charts/TotalPassedCourseChart'
import BlockFilters from './Filter/BlockFilters'
import GlobalFilter from './Filter/GlobalFilter'
import { sectionContent } from './GeneralStatistics.module.scss'

interface ICourseStatisticsOwnProps {
  path: string
}

interface ICourseStatisticsStateProps {
  dispatch: AppDispatch
  organization: IUserManufacturer
  loading: boolean
}

type PharmacyOrganizationStatisticsProps = ICourseStatisticsOwnProps & ICourseStatisticsStateProps

const PharmacyOrganizationStatistics = (
  props: PharmacyOrganizationStatisticsProps
): ReactElement<PharmacyOrganizationStatisticsProps> => {
  const ownOrganizationFilter = useAppSelector(
    (state) => state.manufacturerBranchStatistics.publicationByOwnManufcaturerBranch
  )
  const anotherOrganizationFilter = useAppSelector(
    (state) => state.manufacturerBranchStatistics.publicationByPharmacyOrganizationBranch
  )
  const anotherInOwnOrganizationFilter = useAppSelector(
    (state) => state.manufacturerBranchStatistics.publicationByAnotherManufacturerBranch
  )
  const categoriesSelected = useAppSelector((state) => state.manufacturerBranchStatistics.categoriesSelected)

  useEffect(() => {
    props.dispatch(changeFilters())
  }, [])

  useEffect(() => {
    if (props.organization?.id) {
      const id = GuidHelper.toString(props.organization.id)
      props.dispatch(fetchUsers(id))
    }
    return () => {
      props.dispatch(clearUsers())
    }
  }, [props.organization])

  return props.loading ? (
    <LoaderSpinner />
  ) : (
    <div className={sectionContent}>
      <BlockFilters />
      <GlobalFilter />
      <CourseByAuthorChart />
      <SuccessfulPublicationByAuthorChart />
      <TotalPassedCourseChart />
      <PublicationByCategoryChart
        ownOrganizationFilter={ownOrganizationFilter}
        anotherInOwnOrganizationFilter={anotherInOwnOrganizationFilter}
        anotherOrganizationFilter={anotherOrganizationFilter}
        categoriesSelected={categoriesSelected}
        byOwnOrganizationQueryKey="PublicationByOwnManufcaturerBranch"
        byAnotherInOwnOrganizationQueryKey="PublicationByAnotherManufacturerBranch"
        byAnotherOrganizationQueryKey="PublicationByPharmacyOrganizationBranch"
        byOwnOrganizationCount="PublicationByOwnManufcaturerBranch.count"
        byAnotherInOwnOrganizationCount="PublicationByAnotherManufacturerBranch.count"
        byAnotherOrganizationCount="PublicationByPharmacyOrganizationBranch.count"
        byOwnOrganizationCreateDate="PublicationByOwnManufcaturerBranch.courseStartDate"
        byAnotherInOwnOrganizationStartDate="PublicationByAnotherManufacturerBranch.CourseStartMonth"
        byAnotherOrganizationStartDate="PublicationByPharmacyOrganizationBranch.CourseStartMonth"
        orderOwnOrganization={{ 'PublicationByOwnManufcaturerBranch.courseStartDate': 'asc' }}
        orderAnotherInOwnOrganization={{ 'PublicationByAnotherManufacturerBranch.courseStartDate': 'asc' }}
        orderAnotherOrganization={{ 'PublicationByPharmacyOrganizationBranch.courseStartDate': 'asc' }}
        anotherOrganizationBranchIdSelector={'CourseWithOutFilters.pharmacyOrganizationBranchId'}
        anotherInOwnOrganizationBranchIdSelector={'CourseWithOutFilters.manufacturerBranchId'}
        type={'manufacturer'}
      />
    </div>
  )
}

const mapStateToProps = (store: IAppState): ICourseStatisticsStateProps => {
  return {
    dispatch: store.dispatch,
    organization: store.manufacturer.info,
    loading: store.manufacturerBranchStatistics.loading,
  }
}

export default connect<ICourseStatisticsStateProps, {}, ICourseStatisticsOwnProps, IAppState>(mapStateToProps)(
  PharmacyOrganizationStatistics
)
