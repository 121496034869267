import React, { ReactElement, useEffect } from 'react'
import { connect } from 'react-redux'

import AccountOperationTable from 'shared/components/Offices/Accounts/AccountOperationTable'
import { IAccountBalance } from 'shared/proto/models'

import { getAccountBalance } from '../../../reducers/manufacturerReducer'
import { AppDispatch, IAppState } from '../../../reducers/rootReducer'

const controllerUrl = 'manufacturer-office/manufacturer-account'

interface IManufacturerAccountOwnProps {
  path: string
}

interface IManufacturerAccountStateProps {
  dispatch: AppDispatch
  balance: IAccountBalance
  loadBalance: boolean
}

type ManufacturerAccountProps = IManufacturerAccountOwnProps & IManufacturerAccountStateProps

const ManufacturerAccount = (props: ManufacturerAccountProps): ReactElement => {
  useEffect(() => {
    props.dispatch(getAccountBalance())
  }, [])

  return (
    <AccountOperationTable
      dispatch={props.dispatch}
      loadBalance={props.loadBalance}
      balance={props.balance}
      controllerUrl={controllerUrl}
    />
  )
}

const mapStateToProps = (store: IAppState): IManufacturerAccountStateProps => {
  return {
    dispatch: store.dispatch,
    balance: store.manufacturer.account.balance,
    loadBalance: store.manufacturer.account.loadBalance,
  }
}

export default connect<IManufacturerAccountStateProps, unknown, IManufacturerAccountOwnProps, IAppState>(
  mapStateToProps
)(ManufacturerAccount)
