import { connect } from 'react-redux'

import { Filter, FilterDispatchProps, FilterStateProps } from 'shared/components/CourseStatistics'
import { IFilterSearch } from 'shared/proto/models'

import {
  setBusinessCategories,
  setDrugs,
  setGlobalFilterOpen,
  setMedicalCategories,
} from '../../../../reducers/manufacturerBranchStatisticsReducer'
import { AppDispatch, IAppState } from '../../../../reducers/rootReducer'

const mapStateToProps = (state: IAppState): FilterStateProps => {
  const s = state.manufacturerBranchStatistics
  return {
    open: s.globalFilterOpen,
    businessCategories: s.businessCategories,
    medicalCategories: s.medicalCategories,
    drugNames: s.drugNames,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch): FilterDispatchProps => {
  return {
    setFilterOpen: () => {
      dispatch(setGlobalFilterOpen())
    },
    setBusinessCategories: (values: IFilterSearch[]) => {
      dispatch(setBusinessCategories(values))
    },
    setMedicalCategories: (values: IFilterSearch[]) => {
      dispatch(setMedicalCategories(values))
    },
    setDrugNames: (values: IFilterSearch[]) => {
      dispatch(setDrugs(values))
    },
  }
}

export default connect<FilterStateProps, FilterDispatchProps, unknown, IAppState>(
  mapStateToProps,
  mapDispatchToProps
)(Filter)
