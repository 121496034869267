import React, { ReactElement } from 'react'
import CloseButton from 'shared/components/Buttons/CloseButton'

import { Dialog } from '@material-ui/core'

import { IStaffAddRequest, IStaffToAddList } from '../../../proto/models'
import { IAddEmployeesRequest } from '../../../reducers/manufacturerReducer'
import AddStaffTable from './AddStaffTable'
import { modal, modalClose, modalHeader, modalTitle } from './ManufacturerSubdivisionStaff.module.scss'

interface IAddStaffContext {
  subdivisionId: string
  open: boolean
  loadingTable: boolean
  handleClose: () => void
  addEmployee: (options: IStaffAddRequest) => void
  getAddEmployees: (options: IAddEmployeesRequest) => Promise<IStaffToAddList>
}

const AddStaffModal = (props: IAddStaffContext): ReactElement<IAddStaffContext> => {
  const addClickButtonHandler = (parameters: IStaffAddRequest) => {
    if (parameters.userIds?.length !== 0) {
      props.handleClose()
      props.addEmployee(parameters)
    }
  }

  return (
    <Dialog open={props.open} onClose={props.handleClose} maxWidth={false}>
      <div className={modal}>
        <header className={modalHeader}>
          <h2 className={modalTitle}>Добавление сотрудников</h2>
          <div className={modalClose}>
            <CloseButton onClick={props.handleClose} color="grey" isHover={true} />
          </div>
        </header>
        <AddStaffTable
          getAddEmployees={props.getAddEmployees}
          subdivisionId={props.subdivisionId}
          addClickButtonHandler={addClickButtonHandler}
          cancelClickButtonHandler={() => props.handleClose()}
          loading={props.loadingTable}
        />
      </div>
    </Dialog>
  )
}

export default AddStaffModal
