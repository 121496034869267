import { withPrefix } from 'gatsby'
import React, { useEffect } from 'react'
import nextId from 'react-id-generator'
import { connect } from 'react-redux'

import { DateHelper } from '../../../external/rp.ui/helpers/DateHelper'
import Breadcrumbs, { IBreadcrumbs } from '../../../shared/components/Breadcrumbs'
import LoaderSpinner from '../../../shared/components/LoaderSpinner'
import NavigationTab from '../../../shared/components/NavigationTab/NavigationTab'
import PageInfoHeader from '../../../shared/components/PageInfoHeader'
import PageTitle from '../../../shared/components/PageTitle'
import { ICourseInfoForm } from '../../../shared/proto/models'
import { loadCourseInfo } from '../../../shared/reducers/courseInfo/courseInfoReducer'
import { AppDispatch, IAppState } from '../../reducers/rootReducer'
import { section } from './CourseInfoPage.module.scss'

export interface IEmployeeCourseInfoOwnProps {
  path?: string
  courseId?: string
  subdivisionId?: string
  employeeId?: string
  children?: React.ReactNode
}

interface IEmployeeCourseInfoStateProps {
  dispatch: AppDispatch
  course: ICourseInfoForm
  imagePath: string
}

type EmployeeCourseInfoProps = IEmployeeCourseInfoOwnProps & IEmployeeCourseInfoStateProps

const getDataBreadcrumbs = (props: EmployeeCourseInfoProps): IBreadcrumbs[] => {
  return [
    {
      title: 'Кабинет производителя',
      link: withPrefix('manufacturer-office/organization/'),
    },
    {
      title: 'Подразделение',
      link: withPrefix(`manufacturer-office/organization/subdivision/${props.subdivisionId}`),
    },
    {
      title: 'Курсы',
      link: withPrefix(
        `manufacturer-office/organization/subdivision/${props.subdivisionId}/employee/${props.employeeId}/courses`
      ),
    },
    {
      title: 'Информация о курсе',
      currSection: true,
    },
  ]
}

const getDataNavigation = (props: EmployeeCourseInfoProps) => {
  return [
    {
      id: nextId(),
      name: 'Информация',
      link: withPrefix(
        `manufacturer-office/organization/subdivision/${props.subdivisionId}/employee/${props.employeeId}/course/${props.courseId}/info`
      ),
    },
    {
      id: nextId(),
      name: 'Отзывы',
      link: withPrefix(
        `manufacturer-office/organization/subdivision/${props.subdivisionId}/employee/${props.employeeId}/course/${props.courseId}/reviews`
      ),
    },
    {
      id: nextId(),
      name: 'Статистика',
      link: withPrefix(
        `manufacturer-office/organization/subdivision/${props.subdivisionId}/employee/${props.employeeId}/course/${props.courseId}/statistics`
      ),
    },
  ]
}

const getDataPageInfoHeader = (props: EmployeeCourseInfoProps) => {
  return [
    {
      id: nextId(),
      title: 'Наименование',
      value: props.course?.name,
    },
    {
      id: nextId(),
      title: 'Дата создания ',
      value: props.course?.createDate
        ? DateHelper.fromNumber(props.course.createDate as number).format('DD.MM.YYYY')
        : '',
    },
    {
      id: nextId(),
      title: 'Язык',
      value: props.course?.language,
    },
    {
      id: nextId(),
      title: 'Автор',
      value: props.course?.author,
    },
    {
      id: nextId(),
      title: 'Количество публикаций',
      value: props.course?.publicationCount.toString(),
    },

    {
      id: nextId(),
      title: 'Вопросы',
      value: props.course?.questionCount.toString(),
    },
  ]
}

const EmployeeCourseInfo = (props: EmployeeCourseInfoProps) => {
  const { courseId } = props

  useEffect(() => {
    props.dispatch(loadCourseInfo(courseId, 'manufacturer-office/employee-course/get-course/'))
  }, [courseId])

  return !props.course ? (
    <LoaderSpinner />
  ) : (
    <div className={section}>
      <Breadcrumbs list={getDataBreadcrumbs(props)} />
      <PageTitle title="Информация о курсе" paddingBottom={33} />
      <PageInfoHeader data={getDataPageInfoHeader(props)} image={props.imagePath} />
      <NavigationTab data={getDataNavigation(props)} />
      {props.children}
    </div>
  )
}

const mapStateToProps = (store: IAppState): IEmployeeCourseInfoStateProps => {
  return {
    dispatch: store.dispatch,
    course: store.courseInfo.course,
    imagePath: store.courseInfo.imagePath,
  }
}

export default connect<IEmployeeCourseInfoStateProps, unknown, IEmployeeCourseInfoOwnProps, IAppState>(mapStateToProps)(
  EmployeeCourseInfo
)
