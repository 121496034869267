import { withPrefix } from 'gatsby'
import { IUserManufacturer } from 'manufacturer-office/proto/models'
import React from 'react'
import nextId from 'react-id-generator'
import { connect } from 'react-redux'

import { IDataNavigationTab } from '../../../shared/components/NavigationTab/NavigationTab'
import { IPageInfoHeader } from '../../../shared/components/PageInfoHeader'
import Statistics, {
  IStatisticsOwnProps,
  IStatisticsStateProps,
  StatisticsProps,
} from '../../../shared/components/StatisticsPage/Statistics'
import { getInfo } from '../../reducers/manufacturerReducer'
import { AppDispatch, IAppState } from '../../reducers/rootReducer'

const dataNavigation: IDataNavigationTab[] = [
  {
    id: 1,
    name: 'Общая',
    nameComponent: 'general',
    link: withPrefix('manufacturer-office/statistics'),
  },
  {
    id: 2,
    name: 'Курсы',
    nameComponent: 'courses',
    link: withPrefix('manufacturer-office/statistics/courses'),
  },
]

const headerDataGetter = (organization: IUserManufacturer): IPageInfoHeader[] => {
  return [
    {
      id: nextId(),
      title: 'Наименование',
      value: organization?.shortName,
    },
    {
      id: nextId(),
      title: 'Номер договора',
      value: organization?.contractCode,
    },
  ]
}

const StatisticsPage = (props: StatisticsProps) => (
  <Statistics dataNavigation={(_) => dataNavigation} headerDataGetter={headerDataGetter} {...props} />
)

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    fetchOrganization: () => {
      dispatch(getInfo())
    },
  }
}

const mapStateToProps = (store: IAppState): IStatisticsStateProps => {
  return {
    organization: store.manufacturer.info,
    isShowInfoHeader: true
  }
}

export default connect<IStatisticsStateProps, {}, IStatisticsOwnProps, IAppState>(
  mapStateToProps,
  mapDispatchToProps
)(StatisticsPage)
