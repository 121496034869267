import classNames from 'classnames'
import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import throttleFetch from 'external/rp.ui/utils/throttleFetch'
import { Link, withPrefix } from 'gatsby'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import {
  Column,
  ColumnInterface,
  Row,
  TableOptions,
  useAsyncDebounce,
  useExpanded,
  UseExpandedRowProps,
  UseGroupByRowProps,
  useTable,
} from 'react-table'
import DeleteButton from 'shared/components/Buttons/DeleteButton'
import EditButton from 'shared/components/Buttons/EditButton'
import ArrowIcon from 'shared/components/Icons/ArrowIcon'
import PreviewIcon from 'shared/components/Icons/PreviewIcon'
import AlertObjectRemove, { IAlertObjectRemoveProps } from 'shared/components/Modal/AlertObjectRemove'
import Notification from 'shared/components/Notification'
import Table from 'shared/components/Table'
import {
  tableButtons,
  tableButtonsPreview,
  tableCell,
  tableText,
  tableTextContainer,
} from 'shared/components/Table/Table.module.scss'
import { IPagingAndSort, PagingAndSort } from 'shared/proto/models'

import { IManufacturerSubdivision } from '../../../proto/models'
import { deleteSubdivision, getSubdivision, ISubdivisionFormValues } from '../../../reducers/manufacturerReducer'
import { AppDispatch } from '../../../reducers/rootReducer'
import {
  branch,
  branchIcon,
  branchSecondary,
  tableCellButtons,
  tableCellMemberCount,
  tableCellName,
} from './ManufacturerSubdivision.module.scss'

const throttledDelete = throttleFetch((id: string, dispatch: AppDispatch) => {
  dispatch(deleteSubdivision(id))
})

const throttledFetch = throttleFetch((paging: IPagingAndSort, dispatch: AppDispatch) => {
  return dispatch(getSubdivision(paging))
})

interface IOnFetchDataProps {
  setFetchedData: (value: IManufacturerSubdivision[] | null) => void
  dispatch: AppDispatch
}

const onFetchData = async (props: IOnFetchDataProps) => {
  const paging = PagingAndSort.create({
    pageIndex: 1,
    pageSize: 1000,
    sortColumn: 'name',
    sortDirection: 'desc',
  })

  const data = await throttledFetch(paging, props.dispatch)

  if (data) {
    props.setFetchedData(data.items)
  }
}

interface IDeleteRecordModalProps {
  Id: string
  name: string
  dispatch: AppDispatch
}

const DeleteRecordModal = (props: IDeleteRecordModalProps) => {
  const [open, setOpen] = useState(false)
  const { Id, name } = props

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const agreeButtonClickHandler = async () => {
    handleClose()
    await throttledDelete(Id, props.dispatch)
  }

  const disagreeButtonClickHandler = () => {
    handleClose()
  }

  const modalParams: IAlertObjectRemoveProps = {
    id: Id,
    title: `Вы уверены, что хотите удалить подразделение «${name}»?`,
    agreeString: 'Удалить',
    disagreeString: 'Отменить',
    agreeCallback: agreeButtonClickHandler,
    disagreeCallback: disagreeButtonClickHandler,
    isOpen: open,
    handleClose: handleClose,
  }

  return (
    <>
      <DeleteButton onClick={handleOpen} iconColor="blue" />
      <AlertObjectRemove {...modalParams} />
    </>
  )
}

interface IManufacturerSubdivisionTableProps {
  loadTable: boolean
  dispatch: AppDispatch
  onEditButton: (data: ISubdivisionFormValues) => void
}

const ManufacturerSubdivisionTable = (
  props: IManufacturerSubdivisionTableProps
): ReactElement<IManufacturerSubdivisionTableProps> => {
  const [fetchedData, setFetchedData] = useState<IManufacturerSubdivision[]>([])
  const [notification, setNotification] = useState(false)
  const [notificationMessage, setNotificationMessage] = useState('')

  type columnRowProps = Row<IManufacturerSubdivision> &
    UseExpandedRowProps<IManufacturerSubdivision> &
    UseGroupByRowProps<IManufacturerSubdivision>

  const editButtonHandler = (row: columnRowProps) => {
    const data: ISubdivisionFormValues = {
      id: row.original.id,
      manufacturerSubdivisionName: row.original.name,
      parentManufacturerSubdivision: {
        id: row.original.parentId,
        name: row.original.parentName,
      },
    }
    props.onEditButton(data)
  }

  const deleteButtonHandler = (employee: boolean, courses: boolean) => {
    const msg = 'Невозможно удалить подразделение, у которого есть'
    if (employee && courses) {
      setNotificationMessage(`${msg} сотрудники, дочерние курсы`)
    } else if (employee) {
      setNotificationMessage(`${msg} сотрудники`)
    } else if (courses) {
      setNotificationMessage(`${msg} дочерние курсы`)
    }

    setNotification(true)
  }

  const columns: Column<IManufacturerSubdivision>[] = useMemo(
    () => [
      {
        Header: 'Наименование',
        id: 'name',
        // eslint-disable-next-line react/display-name
        Cell: ({ row }: { row: columnRowProps }) => {
          return (
            <div className={classNames(branch, { [branchSecondary]: row.depth > 0 })}>
              {row.depth > 0 && (
                <span
                  {...row.getToggleRowExpandedProps({
                    style: {
                      paddingLeft: !row.canExpand ? `${row.depth * 20}px` : `${(row.depth - 1) * 24}px`,
                    },
                  })}
                  className={branchIcon}
                ></span>
              )}
              {row.canExpand && (
                <span {...row.getToggleRowExpandedProps()} className={branchIcon}>
                  {row.isExpanded ? <ArrowIcon position="down" /> : <ArrowIcon position="right" />}
                </span>
              )}
              <span className={tableText}>{row.original.name}</span>
            </div>
          )
        },
      },
      {
        Header: 'Сотрудники',
        accessor: 'memberCount',
        // eslint-disable-next-line react/display-name
        Cell: ({ row }: { row: columnRowProps }) => (
          <p className={tableTextContainer}>
            <span className={tableText}>{getMemberSum(row)}</span>
          </p>
        ),
      },
      {
        id: 'buttons',
        disableSortBy: true,
        // eslint-disable-next-line react/display-name
        Cell: ({ row }: { row: columnRowProps }) => {
          const employee = row.original.memberCount > 0
          const courses = row.original.hasContent

          return (
            <div className={tableButtons}>
              <Link
                className={tableButtonsPreview}
                to={withPrefix(`manufacturer-office/organization/subdivision/${GuidHelper.toString(row.original.id)}`)}
              >
                <PreviewIcon />
              </Link>
              <EditButton iconColor="blue" onClick={() => editButtonHandler(row)} />
              {employee || courses ? (
                <DeleteButton onClick={() => deleteButtonHandler(employee, courses)} iconColor="blue" />
              ) : (
                <DeleteRecordModal
                  Id={GuidHelper.toString(row.original.id)}
                  name={row.original.name}
                  dispatch={props.dispatch}
                />
              )}
            </div>
          )
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const getMemberSum = (row: Row<IManufacturerSubdivision>): number => {
    return row.subRows.reduce((acc, subRow) => acc + getMemberSum(subRow), row.original.memberCount)
  }

  const getSubRows = (row: IManufacturerSubdivision): IManufacturerSubdivision[] => {
    const rowId = GuidHelper.toString(row.id)

    return fetchedData.filter((r) => {
      const parentId = GuidHelper.toString(r.parentId)

      return rowId === parentId
    })
  }

  const data = useMemo(() => {
    // eslint-disable-next-line no-prototype-builtins
    return fetchedData.filter((d) => !d.hasOwnProperty('parentId'))
  }, [fetchedData])

  const tableOptions: TableOptions<IManufacturerSubdivision> = {
    data: data,
    columns: columns,
    getSubRows: getSubRows,
  }

  const onFetchDataDebounced = useAsyncDebounce(onFetchData, 1000)

  useEffect(() => {
    if (props.loadTable === true) {
      onFetchDataDebounced({ setFetchedData, dispatch: props.dispatch })
    }
  }, [props.loadTable])

  useEffect(() => {
    onFetchDataDebounced({ setFetchedData, dispatch: props.dispatch })

    return () => {
      setFetchedData([])
      setNotification(false)
      setNotificationMessage('')
    }
  }, [])

  const table = useTable<IManufacturerSubdivision>(tableOptions, useExpanded)

  const getCellClass = (column: ColumnInterface<IManufacturerSubdivision>) => {
    switch (column.id) {
      case 'name': {
        return classNames(tableCell, tableCellName)
      }
      case 'memberCount': {
        return classNames(tableCell, tableCellMemberCount)
      }
      case 'buttons': {
        return classNames(tableCell, tableCellButtons)
      }
    }
  }

  return (
    <>
      <Table<IManufacturerSubdivision> tableInstance={table} loading={props.loadTable} getCellClass={getCellClass} />
      <Notification
        onClose={() => setNotification(false)}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={notification}
        text={notificationMessage}
        response="negative"
      />
    </>
  )
}

export default ManufacturerSubdivisionTable
