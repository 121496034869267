import React from 'react'
import { connect } from 'react-redux'
import CourseInfo from 'shared/components/CourseInfo/CourseInfo'
import { ICourseInfoForm } from 'shared/proto/models'

import { IAppState } from '../../reducers/rootReducer'
import { sectionContent } from './CourseInfoPage.module.scss'

interface ICourseInfoPageOwnProps {
  path?: string
}

interface ICourseInfoPageStateProps {
  course?: ICourseInfoForm
}

type CourseInfoPageProps = ICourseInfoPageOwnProps & ICourseInfoPageStateProps

const CourseInfoPage = (props: CourseInfoPageProps): React.ReactElement<CourseInfoPageProps> => {
  return (
    <div className={sectionContent}>
      <CourseInfo course={props.course} />
    </div>
  )
}

const mapStateToProps = (store: IAppState): ICourseInfoPageStateProps => {
  return {
    course: store.courseInfo.course,
  }
}

export default connect<ICourseInfoPageStateProps, {}, ICourseInfoPageOwnProps, IAppState>(mapStateToProps)(
  CourseInfoPage
)
