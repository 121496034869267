import { DateTime } from 'luxon'
import { EPublicationStatus, IUserListResponse, IUserListResponses, UserListResponses } from 'shared/proto/models'

import { Filter } from '@cubejs-client/core'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { createAction, createReducer } from '@reduxjs/toolkit'
import { IFilterSearch } from 'shared/proto/models'

import { AppDispatch, IAppState } from './rootReducer'

const startDate = DateTime.local().minus({ days: 30 })
const endDate = DateTime.local()

export interface IManufacturerBranchStatisticFiltersState {
  startDate: DateTime
  endDate: DateTime
  filterOpen: boolean
  businessCategories: IFilterSearch[]
  medicalCategories: IFilterSearch[]
  courseByAuthorFilters: Filter[]
  successfulPublicationByAuthorChart: Filter[]
  totalPassedCourseChartFilter: Filter[]
  totalFailedCourseChartFilter: Filter[]
  totalTimedOutCourseChartFilter: Filter[]
  publicationByOwnManufcaturerBranch: Filter[]
  publicationByAnotherManufacturerBranch: Filter[]
  publicationByPharmacyOrganizationBranch: Filter[]
  drugNames: IFilterSearch[]
  globalFilterOpen: boolean
  users: IUserListResponse[]
  loading: boolean
  categoriesSelected: boolean
}

const initialState: IManufacturerBranchStatisticFiltersState = {
  startDate: startDate,
  endDate: endDate,
  filterOpen: false,
  businessCategories: [],
  medicalCategories: [],
  courseByAuthorFilters: [],
  successfulPublicationByAuthorChart: [],
  totalPassedCourseChartFilter: [],
  totalFailedCourseChartFilter: [],
  totalTimedOutCourseChartFilter: [],
  publicationByOwnManufcaturerBranch: [],
  publicationByAnotherManufacturerBranch: [],
  publicationByPharmacyOrganizationBranch: [],
  drugNames: [],
  globalFilterOpen: false,
  users: [],
  loading: true,
  categoriesSelected: false,
}

export const startDateToString = (startDate: DateTime): string =>
  startDate.startOf('day').toISO({ includeOffset: false, suppressMilliseconds: true })

export const endDateToString = (startDate: DateTime): string =>
  startDate.endOf('day').toISO({ includeOffset: false, suppressMilliseconds: true })

export const startDateWithInclusionToString = (startDate: DateTime): string =>
  startDate.startOf('day').minus({ seconds: 1 }).toISO({ includeOffset: false, suppressMilliseconds: true })

export const endDateWithInclusionToString = (startDate: DateTime): string =>
  startDate.endOf('day').plus({ seconds: 1 }).toISO({ includeOffset: false, suppressMilliseconds: true })

export const getDateTimeRange = (startDate: DateTime, endDate: DateTime): string[] => [
  startDateToString(startDate),
  endDateToString(endDate),
]

const SET_GLOBAL_FILTER_OPEN = 'MANUFACTURER_STATISTICS_SET_GLOBAL_FILTER_OPEN'
export const setGlobalFilterOpen = createAction(SET_GLOBAL_FILTER_OPEN)

const CHANGE_FILTERS = 'MANUFACTURER_STATISTICS_CHANGE_FILTERS'
export const changeFilters = () => (dispatch: AppDispatch, getState: () => IAppState): void => {
  const state = getState()
  const { startDate, endDate, businessCategories, medicalCategories, drugNames } = state.manufacturerBranchStatistics
  const filters: Filter[] = []

  if (medicalCategories?.length > 0) {
    filters.push({
      dimension: 'CourseNosologyAttachedMedicalCategory.medicalCategoryId',
      operator: 'equals',
      values: medicalCategories.map((v) => v.id),
    })
  }

  if (businessCategories?.length > 0) {
    filters.push({
      dimension: 'CourseBusinessCategory.businessCategoryId',
      operator: 'equals',
      values: businessCategories.map((v) => v.id),
    })
  }

  if (drugNames?.length > 0) {
    filters.push({
      dimension: 'CourseDrug.drugNameId',
      operator: 'equals',
      values: drugNames.map((v) => v.id),
    })
  }

  const getCourseByAuthorFilter = (startDate: DateTime, endDate: DateTime): Filter[] => {
    const filters: Filter[] = [getDateFilters(startDate, endDate)]

    return filters
  }
  const getSuccessfulPublicationByAuthorChart = (startDate: DateTime, endDate: DateTime): Filter[] => {
    const filters: Filter[] = [
      getDateFilters(startDate, endDate),
      {
        or: [
          { dimension: 'Publication.status', operator: 'notSet' },
          {
            dimension: 'Publication.status',
            operator: 'equals',
            values: [`${EPublicationStatus.Active}`, `${EPublicationStatus.Inactive}`],
          },
        ],
      },
    ]

    return filters
  }

  const getTotalPassedCourseChartFilter = (startDate: DateTime, endDate: DateTime): Filter[] => {
    const filters: Filter[] = [
      {
        dimension: 'ManufacturerPublicationByPharmacyBranch.createDate',
        operator: 'inDateRange',
        values: getDateTimeRange(startDate, endDate),
      },
    ]

    return filters
  }

  const getTotalFailedCourseChartFilter = (startDate: DateTime, endDate: DateTime): Filter[] => {
    const filters: Filter[] = [
      {
        dimension: 'FailedManufacturerPublicationByPharmacyBranch.createDate',
        operator: 'inDateRange',
        values: getDateTimeRange(startDate, endDate),
      },
    ]

    return filters
  }

  const getTotalTimedOutCourseChartFilter = (startDate: DateTime, endDate: DateTime): Filter[] => {
    const filters: Filter[] = [
      {
        dimension: 'TimedOutManufacturerPublicationByPharmacyBranch.createDate',
        operator: 'inDateRange',
        values: getDateTimeRange(startDate, endDate),
      },
    ]

    return filters
  }

  const getPublicationByOwnManufcaturerBranch = (startDate: DateTime, endDate: DateTime): Filter[] => {
    const filters: Filter[] = [
      {
        dimension: 'PublicationByOwnManufcaturerBranch.courseStartDate',
        operator: 'afterDate',
        values: [startDateWithInclusionToString(startDate)],
      },
      {
        dimension: 'PublicationByOwnManufcaturerBranch.courseStartDate',
        operator: 'beforeDate',
        values: [endDateWithInclusionToString(endDate)],
      },
    ]

    return filters
  }

  const getPublicationByAnotherManufacturerBranch = (startDate: DateTime, endDate: DateTime): Filter[] => {
    const filters: Filter[] = [
      {
        dimension: 'PublicationByAnotherManufacturerBranch.courseStartDate',
        operator: 'afterDate',
        values: [startDateWithInclusionToString(startDate)],
      },
      {
        dimension: 'PublicationByAnotherManufacturerBranch.courseStartDate',
        operator: 'beforeDate',
        values: [endDateWithInclusionToString(endDate)],
      },
    ]

    return filters
  }

  const getPublicationByPharmacyOrganizationBranch = (startDate: DateTime, endDate: DateTime): Filter[] => {
    const filters: Filter[] = [
      {
        dimension: 'PublicationByPharmacyOrganizationBranch.courseStartDate',
        operator: 'afterDate',
        values: [startDateWithInclusionToString(startDate)],
      },
      {
        dimension: 'PublicationByPharmacyOrganizationBranch.courseStartDate',
        operator: 'beforeDate',
        values: [endDateWithInclusionToString(endDate)],
      },
    ]

    return filters
  }

  const payload = {
    courseByAuthorFilters: filters.concat(getCourseByAuthorFilter(startDate, endDate)),
    successfulPublicationByAuthorChart: filters.concat(getSuccessfulPublicationByAuthorChart(startDate, endDate)),
    totalPassedCourseChartFilter: filters.concat(getTotalPassedCourseChartFilter(startDate, endDate)),
    totalFailedCourseChartFilter: filters.concat(getTotalFailedCourseChartFilter(startDate, endDate)),
    totalTimedOutCourseChartFilter: filters.concat(getTotalTimedOutCourseChartFilter(startDate, endDate)),
    publicationByOwnManufcaturerBranch: filters.concat(getPublicationByOwnManufcaturerBranch(startDate, endDate)),
    publicationByAnotherManufacturerBranch: filters.concat(
      getPublicationByAnotherManufacturerBranch(startDate, endDate)
    ),
    publicationByPharmacyOrganizationBranch: filters.concat(
      getPublicationByPharmacyOrganizationBranch(startDate, endDate)
    ),
  }

  dispatch({ type: CHANGE_FILTERS, payload: payload })
}

const getDateFilters = (startDate: DateTime, endDate: DateTime): Filter => ({
  dimension: 'Course.createDate',
  operator: 'inDateRange',
  values: getDateTimeRange(startDate, endDate),
})

const SELECT_CATEGORIES = 'SELECT_CATEGORIES'
const selectCategories = createAction<boolean>(SELECT_CATEGORIES)

const CHANGE_START_DATE = 'MANUFACTURER_STATISTICS_CHANGE_START_DATE'
const changeStartDateAction = createAction<DateTime>(CHANGE_START_DATE)
export const changeStartDate = (date: DateTime) => (dispatch: AppDispatch): void => {
  dispatch(changeStartDateAction(date))
  dispatch(changeFilters())
}

const CHANGE_END_DATE = 'MANUFACTURER_STATISTICS_CHANGE_END_DATE'
const changeEndDateAction = createAction<DateTime>(CHANGE_END_DATE)
export const changeEndDate = (date: DateTime) => (dispatch: AppDispatch): void => {
  dispatch(changeEndDateAction(date))
  dispatch(changeFilters())
}

const SET_BUSINESS_CATEGORIES = 'MANUFACTURER_STATISTICS_SET_BUSINESS_CATEGORIES'
const setBusinessCategoriesAction = createAction<IFilterSearch[]>(SET_BUSINESS_CATEGORIES)
export const setBusinessCategories = (filters: IFilterSearch[]) => (
  dispatch: AppDispatch,
  getState: () => IAppState
): void => {
  const state = getState().manufacturerBranchStatistics
  const categories = filters.concat(state.medicalCategories).concat(state.drugNames)
  dispatch(selectCategories(categories.length !== 0))
  dispatch(setBusinessCategoriesAction(filters))
  dispatch(changeFilters())
}

const SET_MEDICAL_CATEGORIES = 'MANUFACTURER_STATISTICS_SET_MEDICAL_CATEGORIES'
export const setMedicalCategoriesAction = createAction<IFilterSearch[]>(SET_MEDICAL_CATEGORIES)
export const setMedicalCategories = (filters: IFilterSearch[]) => (
  dispatch: AppDispatch,
  getState: () => IAppState
): void => {
  const state = getState().manufacturerBranchStatistics
  const categories = filters.concat(state.businessCategories).concat(state.drugNames)
  dispatch(selectCategories(categories.length !== 0))
  dispatch(setMedicalCategoriesAction(filters))
  dispatch(changeFilters())
}

const SET_DRUGS = 'MANUFACTURER_STATISTICS_SET_DRUGS'
const setDrugsAction = createAction<IFilterSearch[]>(SET_DRUGS)
export const setDrugs = (filters: IFilterSearch[]) => (dispatch: AppDispatch, getState: () => IAppState): void => {
  const state = getState().manufacturerBranchStatistics
  const categories = filters.concat(state.medicalCategories).concat(state.businessCategories)
  dispatch(selectCategories(categories.length !== 0))
  dispatch(setDrugsAction(filters))
  dispatch(setDrugsAction(filters))
  dispatch(changeFilters())
}

const SET_LOADING = 'MAN_STAT_SET_LOADING'
const setLoading = createAction<boolean>(SET_LOADING)

const SET_USERS = 'SET_USERS'
const setUsers = createAction<IUserListResponse[]>(SET_USERS)
export const fetchUsers = (organizationId: string) => async (dispatch: AppDispatch): Promise<void> => {
  const response = await ProtoClient.get<IUserListResponses>(
    `manufacturer-office/manufacturer-branch-statistics/get-users/${organizationId}`,
    UserListResponses
  )
  response.items && dispatch(setUsers(response.items))
  dispatch(setLoading(false))
}

export const clearUsers = () => async (dispatch: AppDispatch): Promise<void> => {
  dispatch(setUsers([]))
}

const manufacturerBranchStatisticsReducer = createReducer(initialState, {
  [CHANGE_START_DATE]: (state, action) => {
    state.startDate = action.payload
  },
  [CHANGE_END_DATE]: (state, action) => {
    state.endDate = action.payload
  },
  [SET_BUSINESS_CATEGORIES]: (state, action) => {
    state.businessCategories = action.payload
  },
  [SET_MEDICAL_CATEGORIES]: (state, action) => {
    state.medicalCategories = action.payload
  },
  [SET_DRUGS]: (state, action) => {
    state.drugNames = action.payload
  },
  [CHANGE_FILTERS]: (state, action) => {
    state.courseByAuthorFilters = action.payload.courseByAuthorFilters
    state.successfulPublicationByAuthorChart = action.payload.successfulPublicationByAuthorChart
    state.totalPassedCourseChartFilter = action.payload.totalPassedCourseChartFilter
    state.totalFailedCourseChartFilter = action.payload.totalFailedCourseChartFilter
    state.totalTimedOutCourseChartFilter = action.payload.totalTimedOutCourseChartFilter
    state.publicationByOwnManufcaturerBranch = action.payload.publicationByOwnManufcaturerBranch
    state.publicationByAnotherManufacturerBranch = action.payload.publicationByAnotherManufacturerBranch
    state.publicationByPharmacyOrganizationBranch = action.payload.publicationByPharmacyOrganizationBranch
  },
  [SET_GLOBAL_FILTER_OPEN]: (state) => {
    state.globalFilterOpen = !state.globalFilterOpen
  },
  [SET_USERS]: (state, action) => {
    state.users = action.payload
  },
  [SET_LOADING]: (state, action) => {
    state.loading = action.payload
  },
  [SELECT_CATEGORIES]: (state, action) => {
    state.categoriesSelected = action.payload
  },
})

export default manufacturerBranchStatisticsReducer
