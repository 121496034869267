// extracted by mini-css-extract-plugin
export var section = "CourseReviews-module--section--17ExQ";
export var sectionNav = "CourseReviews-module--section-nav--1PoUE";
export var sectionContent = "CourseReviews-module--section-content--1BvPu";
export var header = "CourseReviews-module--header--1tsTD";
export var headerTitle = "CourseReviews-module--header-title--14Rj9";
export var headerWrapper = "CourseReviews-module--header-wrapper--36rSi";
export var headerRating = "CourseReviews-module--header-rating--3coFd";
export var headerRatingInfo = "CourseReviews-module--header-rating-info--34fwU";
export var headerRatingText = "CourseReviews-module--header-rating-text--25P7U";
export var paging = "CourseReviews-module--paging--2wwcX";
export var cardList = "CourseReviews-module--card-list--9rUMY";
export var cardItem = "CourseReviews-module--card-item--12QNo";