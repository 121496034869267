import { withPrefix } from 'gatsby'
import React, { ReactElement, useEffect } from 'react'
import nextId from 'react-id-generator'
import { connect } from 'react-redux'

import Breadcrumbs, { IBreadcrumbs } from '../../../../shared/components/Breadcrumbs'
import LoaderSpinner from '../../../../shared/components/LoaderSpinner'
import PageInfoHeader, { IPageInfoHeader } from '../../../../shared/components/PageInfoHeader'
import PageTitle from '../../../../shared/components/PageTitle'
import {
  IManufacturerSubdivisionInfo,
  IManufacturerSubdivisionStaffList,
  IStaffAddRequest,
  IStaffToAddList,
} from '../../../proto/models'
import {
  addEmployee,
  deleteEmployee,
  getAddEmployees,
  getEmployees,
  getStaffInfo,
  IAddEmployeesRequest,
  IEmployeesRequest,
  resetStateStaff,
  setLoadEmployees,
} from '../../../reducers/manufacturerReducer'
import { AppDispatch, IAppState } from '../../../reducers/rootReducer'
import ManufacturerSubdivisionStaffTable from './ManufacturerSubdivisionStaffTable'

interface IManufacturerSubdivisionStaffOwnProps {
  path: string
  id?: string
}

interface IManufacturerSubdivisionStaffStateProps {
  dispatch: AppDispatch
  info: IManufacturerSubdivisionInfo
  loadInfo: boolean
  loadTable: boolean
  loadingAddTable: boolean
}

type ManufacturerSubdivisionStaffProps = IManufacturerSubdivisionStaffOwnProps & IManufacturerSubdivisionStaffStateProps

const ManufacturerSubdivisionStaff = (
  props: ManufacturerSubdivisionStaffProps
): ReactElement<ManufacturerSubdivisionStaffProps> => {
  const dataInfoHeader: IPageInfoHeader[] = [
    {
      id: nextId(),
      title: 'Подразденение',
      value: props.info?.name,
    },
    {
      id: nextId(),
      title: 'Наименование компании',
      value: props.info?.manufacturerName,
    },
    {
      id: nextId(),
      title: 'Сотрудников в подразделении',
      value: props.info?.memberCount.toString(),
    },
  ]

  const dataBreadcrumbs: IBreadcrumbs[] = [
    {
      title: 'Кабинет производителя',
      link: withPrefix(`manufacturer-office/organization`),
    },
    {
      title: 'Информация о подразделении',
      currSection: true,
    },
  ]

  const setEmployeeLoadingHandler = (value: boolean) => {
    props.dispatch(setLoadEmployees(value))
  }

  const getEmployeesHandler = (options: IEmployeesRequest): Promise<IManufacturerSubdivisionStaffList> => {
    return props.dispatch(getEmployees(options))
  }

  const deleteEmployeeHandler = (id: string) => {
    props.dispatch(deleteEmployee(id))
  }

  const addEmployeeHandler = (options: IStaffAddRequest) => {
    props.dispatch(addEmployee(options))
  }

  const getAddEmployeesHadler = (options: IAddEmployeesRequest): Promise<IStaffToAddList> => {
    return props.dispatch(getAddEmployees(options))
  }

  useEffect(() => {
    props.dispatch(getStaffInfo(props.id))

    return () => {
      props.dispatch(resetStateStaff())
    }
  }, [props.id])

  return (
    <>
      <Breadcrumbs list={dataBreadcrumbs} />
      <PageTitle title="Информация о подразделении" paddingBottom={34} />
      {props.loadInfo ? (
        <LoaderSpinner />
      ) : (
        <>
          <PageInfoHeader data={dataInfoHeader} />
          <ManufacturerSubdivisionStaffTable
            getEmployees={getEmployeesHandler}
            loadingEmployees={props.loadTable}
            subdivisionId={props.id}
            loadingAddTable={props.loadingAddTable}
            deleteEmployee={deleteEmployeeHandler}
            addEmployee={addEmployeeHandler}
            getAddEmployees={getAddEmployeesHadler}
            setEmployeeLoading={setEmployeeLoadingHandler}
          />
        </>
      )}
    </>
  )
}

const mapStateToProps = (store: IAppState): IManufacturerSubdivisionStaffStateProps => {
  return {
    dispatch: store.dispatch,
    loadInfo: store.manufacturer.subdivision.staff.loadInfo,
    info: store.manufacturer.subdivision.staff.info,
    loadTable: store.manufacturer.subdivision.staff.loadTable,
    loadingAddTable: store.manufacturer.subdivision.staff.loadAddTable,
  }
}

export default connect<
  IManufacturerSubdivisionStaffStateProps,
  unknown,
  IManufacturerSubdivisionStaffOwnProps,
  IAppState
>(mapStateToProps)(ManufacturerSubdivisionStaff)
