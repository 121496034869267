import AwesomeDebouncePromise from 'awesome-debounce-promise'
import React, { ChangeEvent, useEffect, useState } from 'react'
import {
  Field,
  Form,
  InjectedFormProps,
  reduxForm,
  Validator,
  WrappedFieldInputProps,
  WrappedFieldMetaProps,
} from 'redux-form'
import CloseButton from 'shared/components/Buttons/CloseButton'
import TextButton from 'shared/components/Buttons/TextButton'
import CustomAutocompleteInput from 'shared/components/CustomAutocompleteInput'
import CustomInput from 'shared/components/CustomInput'
import AddIcon from 'shared/components/Icons/AddIcon'
import SaveIcon from 'shared/components/Icons/SaveIcon'

import { Dialog } from '@material-ui/core'
import useAutocomplete, { AutocompleteInputChangeReason } from '@material-ui/lab/useAutocomplete'

import { IManufacturerSubdivisionSearch } from '../../../proto/models'
import { ISubdivisionFormValues } from '../../../reducers/manufacturerReducer'
import { IModalOptions } from './ManufacturerSubdivision'
import {
  modal,
  modalBlockButtons,
  modalBlockSearch,
  modalBlockSelect,
  modalBlockSelectWrraper,
  modalButton,
  modalClose,
  modalForm,
  modalHeader,
  modalSelect,
  modalTitle,
} from './ManufacturerSubdivision.module.scss'

const required: Validator = (value) => {
  return !value || value.toString().trim() === '' ? 'Не заполнено обязательное поле' : undefined
}

interface IAutocompleteFieldProps {
  input: WrappedFieldInputProps
  meta: WrappedFieldMetaProps
  placeholder: string
  disabled: boolean
  label: string
  data: IManufacturerSubdivisionSearch[]
  onSearchSubdivision: (search: string) => void
}

const searchAPIDebounced = AwesomeDebouncePromise((value, func) => {
  func(value)
}, 500)

const AutocompleteField = (props: IAutocompleteFieldProps) => {
  const {
    input: { onChange },
    label,
    data,
  } = props
  const [options, setOptions] = useState<IManufacturerSubdivisionSearch[]>(data)

  const onInputChange = async (event: ChangeEvent<{}>, value: string, reason: AutocompleteInputChangeReason) => {
    if (reason === 'input') {
      searchAPIDebounced(value, props.onSearchSubdivision)
    }

    if (reason === 'reset' || reason === 'clear') {
      setOptions([])
    }
  }

  const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } = useAutocomplete({
    multiple: false,
    onInputChange: onInputChange,
    options: options,
    filterOptions: (options) => options,
    getOptionLabel: (option) => option.name ?? '',
    value: props.input.value,
    onChange: (event, value) => {
      onChange(value)
    },
    getOptionSelected: () => true,
  })

  useEffect(() => {
    setOptions(data)

    return () => setOptions([])
  }, [data])

  return (
    <CustomAutocompleteInput
      meta={props.meta}
      groupedOptions={groupedOptions}
      getRootProps={getRootProps}
      getInputProps={getInputProps}
      getListboxProps={getListboxProps}
      getOptionProps={getOptionProps}
      getOptionLabel={(option) => option.name}
      placeholder={props.placeholder}
      type="text"
      maxHeightResult={150}
      disabled={props.disabled}
      label={label}
      size="normal"
    />
  )
}

const EditForm = (
  props: InjectedFormProps<ISubdivisionFormValues, IManufacturerSubdivisionEditModalContext, string> &
    IManufacturerSubdivisionEditModalContext
) => {
  const { handleSubmit, submitting, open, handleClose, onSearchSubdivision } = props

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose()
      }}
      maxWidth={false}
    >
      <div className={modal}>
        <header className={modalHeader}>
          <h2 className={modalTitle}>{props.options?.title}</h2>
          <div className={modalClose}>
            <CloseButton onClick={handleClose} color="grey" isHover={true} />
          </div>
        </header>
        <Form onSubmit={handleSubmit} className={modalForm}>
          <div className={modalBlockSearch}>
            <Field
              placeholder="Введите наименование"
              name="manufacturerSubdivisionName"
              component={CustomInput}
              label="Наименование"
              isReserveLabelError={true}
              validate={required}
              size="normal"
            />
          </div>
          <div className={modalBlockSelect}>
            <div className={modalBlockSelectWrraper}>
              <div className={modalSelect}>
                <Field
                  name="parentManufacturerSubdivision"
                  component={AutocompleteField}
                  placeholder="Выберите подразделение"
                  label="Родительское подразделение"
                  data={props.searchSubdivision}
                  onSearchSubdivision={onSearchSubdivision}
                />
              </div>
              <TextButton
                variant="contained"
                text="Очистить поле"
                onClick={() => props.onClearButton('parentManufacturerSubdivision')}
              />
            </div>
          </div>
          <div className={modalBlockButtons}>
            <div className={modalButton}>
              <TextButton
                iconLeft={props.options?.id === 'create' ? <AddIcon color="white" /> : <SaveIcon />}
                size="big"
                disabled={submitting}
                text={props.options?.submitButtonText}
                type="submit"
              />
            </div>
            <div className={modalButton}>
              <TextButton size="big" variant="outlined" text="Отменить" onClick={handleClose} />
            </div>
          </div>
        </Form>
      </div>
    </Dialog>
  )
}

export interface IManufacturerSubdivisionEditModalContext {
  handleClose: () => void
  open: boolean
  options: IModalOptions
  onClearButton: (name: string) => void
  onSearchSubdivision: (searchString: string) => void
  searchSubdivision: IManufacturerSubdivisionSearch[]
}

export default reduxForm<ISubdivisionFormValues, IManufacturerSubdivisionEditModalContext>({
  form: 'manufacturerSubdivisionEditorForm',
})(EditForm)
