import { withPrefix } from 'gatsby'
import { IAppState } from 'manufacturer-office/reducers/rootReducer'
import React from 'react'
import { connect } from 'react-redux'
import ProvisorExperience from 'shared/components/ProvisorExperience'
import { IExperienceDetail } from 'shared/proto/models'
import { fetchPermissions } from 'shared/reducers/CourseDesigner/permissionsReducer'

import SafeLink from '../../../shared/components/SafeLink'
import Header from '../../../shared/components/Sidebar/Header'
import Navigation, { IDataNavigation } from '../../../shared/components/Sidebar/Navigation'
import { AppDispatch } from '../../../shared/reducers/CourseDesigner'
import { fetchMenuItems } from '../../../shared/reducers/menuItems/menuItemsReducer'
import { getProvisorExperience } from '../../../shared/reducers/provisorExperience/provisorExperienceReducer'
import UserInfo from '../UserInfo/UserInfo'
import { sidebar } from './Sidebar.module.scss'

const dataNavigation: (IDataNavigation<unknown> & { key: string })[] = [
  {
    id: 1,
    name: 'Кабинет производителя',
    link: withPrefix(`manufacturer-office/organization`),
    class: 'manufacturer',
    key: 'manufacturer-office',
  },
  {
    id: 2,
    name: 'Конструктор курсов',
    link: withPrefix(`manufacturer-office/course-designer`),
    class: 'course-designer',
    key: 'course-designer',
  },
  {
    id: 3,
    name: 'Статистика',
    link: withPrefix(`manufacturer-office/statistics`),
    class: 'statistics',
    key: 'statistics',
  },
  {
    id: 4,
    name: 'Личный профиль',
    link: withPrefix(`manufacturer-office/profile`),
    class: 'profile',
    key: 'profile',
  },
]

interface ISidebarStateProps {
  loading: boolean
  menuItems: string[]
  provisors: IExperienceDetail[]
  totalProvisors: number
  provisorsLoading: boolean
}

interface ISidebarDispatchProps {
  fetchMenuItems: () => void
  getProvisorExperience: () => void
  fetchPermissions: () => void
}

type ISidebarProps = ISidebarStateProps & ISidebarDispatchProps

class Sidebar extends React.Component<ISidebarProps> {
  componentDidMount() {
    this.props.fetchMenuItems()
    this.props.getProvisorExperience()
    this.props.fetchPermissions()
  }

  private getMenuItems = () => {
    const menuItems = this.props.menuItems
    if (menuItems.length !== 0) {
      return dataNavigation.filter((dn) => menuItems.includes(dn.key))
    }

    return []
  }

  render() {
    return (
      <aside className={sidebar}>
        <Header />
        <UserInfo />
        <Navigation dataNavigation={this.getMenuItems()} linkElement={SafeLink} />
        {!this.props.provisorsLoading && (
          <ProvisorExperience data={this.props.provisors} total={this.props.totalProvisors} />
        )}
      </aside>
    )
  }
}

const mapStateToProps = (store: IAppState): ISidebarStateProps => {
  return {
    loading: store.menuItems.loading,
    menuItems: store.menuItems.menuItems,
    provisors: store.provisorExperience.provisors,
    totalProvisors: store.provisorExperience.total,
    provisorsLoading: store.provisorExperience.loading,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch): ISidebarDispatchProps => {
  return {
    fetchMenuItems: () => {
      dispatch(fetchMenuItems('manufacturer-office/menu/get-menu-items'))
    },
    getProvisorExperience: () => {
      dispatch(getProvisorExperience())
    },
    fetchPermissions: () => {
      dispatch(fetchPermissions('manufacturer-office'))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
