import React from 'react'
import { connect } from 'react-redux'
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import CubeQueryRender, { IChartComponentProps, renderChart } from 'shared/components/QueryRender/CubeQueryRender'
import CustomLegend from 'shared/components/Recharts/CustomLegend'
import CustomTooltip from 'shared/components/Recharts/CustomTooltip'

import { Filter, ResultSet } from '@cubejs-client/core'
import { QueryRendererRenderProps } from '@cubejs-client/react'

import { IAppState } from '../../../../reducers/rootReducer'
import { blockChart, blockChartGraph, blockChartTitle, chart } from './BlockCharts.module.scss'
import { additionalChartStyle, getChartWidth } from './utils'

const assignedKey = 'assigned'
const failedKey = 'failed'
const timedOutKey = 'timedOut'

const passedCount = 'ManufacturerPublicationByPharmacyBranch.countPassed'
const assignedCount = 'ManufacturerPublicationByPharmacyBranch.count'
const failedCount = 'FailedManufacturerPublicationByPharmacyBranch.count'
const timedOutCount = 'TimedOutManufacturerPublicationByPharmacyBranch.count'
const branchName = 'ManufacturerPublicationByPharmacyBranch.name'
const failedBranchName = 'FailedManufacturerPublicationByPharmacyBranch.name'
const timedOutBranchName = 'TimedOutManufacturerPublicationByPharmacyBranch.name'

const barSize = 80
const barSizeBigData = 10
const countBarStyle = 5

export const assignedBarСolor = '#7955A9'
export const passedBarСolor = '#FEC213'
export const failedbarСolor = '#32C9D1'

const getData = (sets: { [key: string]: ResultSet }) => {
  const assignedPivots = sets[assignedKey].chartPivot({
    x: [branchName],
    y: [assignedCount, passedCount],
  })

  const failedPivots = sets[failedKey].chartPivot({
    x: [branchName],
    y: [failedCount],
  })

  const timedOutPivots = sets[timedOutKey].chartPivot({
    x: [branchName],
    y: [timedOutCount],
  })

  const branchNames = [
    ...new Set(
      assignedPivots
        .map((r) => r.xValues[0])
        .concat(failedPivots.map((r) => r.xValues[0]))
        .concat(timedOutPivots.map((r) => r.xValues[0]))
    ),
  ]

  const preparedData = branchNames.map((name) => {
    return {
      [branchName]: name,
      [assignedCount]: assignedPivots.find((p) => p.xValues[0] === name)?.[assignedCount] ?? 0,
      [passedCount]: assignedPivots.find((p) => p.xValues[0] === name)?.[passedCount] ?? 0,
      [failedCount]:
        (failedPivots.find((p) => p.xValues[0] === name)?.[failedCount] ?? 0) +
        (timedOutPivots.find((p) => p.xValues[0] === name)?.[timedOutCount] ?? 0),
    }
  })

  return preparedData
}

const labels = {
  [assignedCount]: 'Назначено курсов',
  [passedCount]: 'Сдано курсов',
  [failedCount]: 'Не сдано курсов',
}

const barRender = (props: IBarRenderProps) => {
  const { resultSet } = props
  const data = getData(resultSet as { [key: string]: ResultSet })
  const [show, setShow] = React.useState<string>(null)

  const memoData = React.useMemo(() => data, [data])

  const legendClickHandler = (dataKey: string) => {
    if (show === dataKey) {
      setShow(null)
    } else {
      setShow(dataKey)
    }
  }

  const width = getChartWidth(data)

  return (
    <ResponsiveContainer width={width} height="100%">
      <BarChart data={data} margin={{ top: 20 }}>
        <XAxis tick={data.length <= countBarStyle} type="category" dataKey={branchName} />
        <YAxis key="YAxis" axisLine={false} tickLine={false} width={30} type="number" allowDecimals={false} />
        <Tooltip content={<CustomTooltip />} />
        <CartesianGrid strokeDasharray="5 5" />
        <Bar
          dataKey={assignedCount}
          stackId="a"
          fill={assignedBarСolor}
          minPointSize={3}
          name={labels[assignedCount]}
          barSize={memoData.length <= countBarStyle ? barSize : barSizeBigData}
          hide={show !== null && show !== assignedCount}
        />
        <Bar
          dataKey={passedCount}
          stackId="b"
          fill={passedBarСolor}
          minPointSize={3}
          name={labels[passedCount]}
          barSize={memoData.length <= countBarStyle ? barSize : barSizeBigData}
          hide={show !== null && show !== passedCount}
        />
        <Bar
          dataKey={failedCount}
          stackId="c"
          fill={failedbarСolor}
          minPointSize={3}
          name={labels[failedCount]}
          barSize={memoData.length <= countBarStyle ? barSize : barSizeBigData}
          hide={show !== null && show !== failedCount}
        />
        <Legend
          wrapperStyle={{ bottom: '-5px' }}
          height={35}
          content={<CustomLegend barData={[assignedCount, passedCount, failedCount]} onClick={legendClickHandler} />}
        />
      </BarChart>
    </ResponsiveContainer>
  )
}

type IBarRenderProps = IChartComponentProps

const chartRender = (props: QueryRendererRenderProps) => (
  <div className={chart} style={additionalChartStyle}>
    {renderChart(barRender)(props)}
  </div>
)

const Chart = (props: TotalPassedCourseChartChartProps) => {
  return (
    <div className={blockChart}>
      <h2 className={blockChartTitle}>Прогресс сдачи курсов по аптечным организациям</h2>
      <div className={blockChartGraph}>
        <CubeQueryRender
          queries={{
            [assignedKey]: {
              measures: [assignedCount, passedCount],
              dimensions: [branchName],
              filters: props.assignedFilters,
            },
            [failedKey]: {
              measures: [failedCount],
              dimensions: [failedBranchName],
              filters: props.failedFilters,
            },
            [timedOutKey]: {
              measures: [timedOutCount],
              dimensions: [timedOutBranchName],
              filters: props.timedOutFilters,
            },
          }}
          render={chartRender}
        />
      </div>
    </div>
  )
}

interface TotalPassedCourseChartChartStateProps {
  assignedFilters: Filter[]
  failedFilters: Filter[]
  timedOutFilters: Filter[]
}
type TotalPassedCourseChartChartProps = TotalPassedCourseChartChartStateProps

const mapStateToProps = (store: IAppState): TotalPassedCourseChartChartProps => {
  return {
    assignedFilters: store.manufacturerBranchStatistics.totalPassedCourseChartFilter,
    failedFilters: store.manufacturerBranchStatistics.totalFailedCourseChartFilter,
    timedOutFilters: store.manufacturerBranchStatistics.totalTimedOutCourseChartFilter,
  }
}

export default connect<TotalPassedCourseChartChartProps, unknown, unknown, IAppState>(mapStateToProps)(Chart)
