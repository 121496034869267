import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import React from 'react'
import { connect } from 'react-redux'
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

import { ChartPivotRow, Filter, ResultSet } from '@cubejs-client/core'
import { QueryRendererRenderProps } from '@cubejs-client/react'

import CubeQueryRender, {
  IChartComponentProps,
  renderChart,
} from '../../../../../shared/components/QueryRender/CubeQueryRender'
import CustomLegend from '../../../../../shared/components/Recharts/CustomLegend'
import CustomTooltip from '../../../../../shared/components/Recharts/CustomTooltip'
import { ECourseStatus, IUserListResponse } from '../../../../../shared/proto/models'
import { IAppState } from '../../../../reducers/rootReducer'
import { blockChart, blockChartGraph, blockChartTitle, chart } from './BlockCharts.module.scss'
import { additionalChartStyle, getAdditionalStyle, getChartWidth } from './utils'

const draftKey = `${ECourseStatus.Draft}`
const onApprovalKey = `${ECourseStatus.OnApproval}`
const rejectedKey = `${ECourseStatus.Rejected}`
const approvedKey = `${ECourseStatus.Approved}`

const courseCount = 'Course.count'
const courseAuthorId = 'Course.userId'
const courseStatus = 'Course.status'

const barSize = 80
const barSizeBigData = 10
const countBarStyle = 5

export const barNames = (key: string): string => {
  switch (key) {
    case draftKey:
      return 'Создан'
    case onApprovalKey:
      return 'В процессе согласования'
    case approvedKey:
      return 'Утвержден'
    default:
      return ''
  }
}

export const barСolor = (key: string): string => {
  switch (key) {
    case draftKey:
      return '#7955A9'
    case onApprovalKey:
      return '#FEC213'
    case approvedKey:
      return '#32C9D1'
    default:
      return ''
  }
}

const applyLabelToBars = (user: IUserListResponse, pivots: ChartPivotRow[]) => {
  const userId = GuidHelper.toString(user.userId).toLowerCase()
  const filteredPivots = pivots.filter((p) => p.xValues[0] === userId)

  const draftPivots = filteredPivots.filter((fp) => +fp.xValues[1] == +draftKey)
  const onApprovalPivots = filteredPivots.filter(
    (fp) => +fp.xValues[1] == +onApprovalKey || +fp.xValues[1] == +rejectedKey
  )
  const approvedPivots = filteredPivots.filter((fp) => +fp.xValues[1] == +approvedKey)

  const draftCount = draftPivots.reduce((acc, curr) => acc + curr[courseCount], 0)
  const approvedCount = approvedPivots.reduce((acc, curr) => acc + curr[courseCount], 0)
  const onApprovalCount = onApprovalPivots.reduce((acc, curr) => acc + curr[courseCount], 0)

  const preparedData = {
    [draftKey]: draftCount,
    [onApprovalKey]: onApprovalCount,
    [approvedKey]: approvedCount,
    ['name']: user.fullName,
  }
  return preparedData
}

const getData = (set: ResultSet, users: IUserListResponse[]) => {
  const pivots = set.chartPivot({
    x: [courseAuthorId, courseStatus],
    y: [courseCount],
  })
  const userIds = [...new Set(pivots.map((r) => r.xValues[0]))]

  const filteredUsers = users.filter((u) => userIds.includes(GuidHelper.toString(u.userId).toLowerCase()))

  const perparedData = filteredUsers.map((i) => applyLabelToBars(i, pivots))

  return perparedData
}

const barRender = (props: IBarRenderProps) => {
  const { resultSet, users } = props
  const data = getData(resultSet as ResultSet, users)
  const memoData = React.useMemo(() => data, [data])

  const barData = [draftKey, onApprovalKey, approvedKey]

  const width = getChartWidth(data)

  return (
    <ResponsiveContainer width={width} height="100%">
      <BarChart data={data} margin={{ top: 20 }}>
        <XAxis hide={true} dataKey="name" />
        <YAxis key="YAxis" axisLine={false} tickLine={false} width={30} />
        <Tooltip content={<CustomTooltip />} />
        <CartesianGrid strokeDasharray="5 5" />
        <Bar
          key={`bar-${1}`}
          dataKey={draftKey}
          stackId="a"
          name={barNames(draftKey)}
          fill={barСolor(draftKey)}
          minPointSize={3}
          barSize={memoData.length <= countBarStyle ? barSize : barSizeBigData}
        />
        <Bar
          key={`bar-${2}`}
          dataKey={onApprovalKey}
          stackId="b"
          name={barNames(onApprovalKey)}
          fill={barСolor(onApprovalKey)}
          minPointSize={3}
          barSize={memoData.length <= countBarStyle ? barSize : barSizeBigData}
        />
        <Bar
          key={`bar-${3}`}
          dataKey={approvedKey}
          stackId="c"
          name={barNames(approvedKey)}
          fill={barСolor(approvedKey)}
          minPointSize={3}
          barSize={memoData.length <= countBarStyle ? barSize : barSizeBigData}
        />
        <Legend wrapperStyle={{ bottom: '-5px' }} height={35} content={<CustomLegend barData={barData} />} />
      </BarChart>
    </ResponsiveContainer>
  )
}

interface IBarRenderStateProps {
  users: IUserListResponse[]
}

type IBarRenderProps = IBarRenderStateProps & IChartComponentProps

const mapStateToBarChartProps = (store: IAppState): IBarRenderStateProps => {
  return {
    users: store.manufacturerBranchStatistics.users,
  }
}
const connectedBarRender = connect<IBarRenderStateProps, {}, IChartComponentProps, IAppState>(mapStateToBarChartProps)(
  barRender
)

const chartRender = (props: QueryRendererRenderProps) => (
  <div className={chart} style={additionalChartStyle}>
    {renderChart(connectedBarRender)(props)}
  </div>
)

const CourseByAuthorChart = (props: CourseByAuthorChartProps) => {
  return (
    <div className={blockChart}>
      <h2 className={blockChartTitle}>Статистика по курсам</h2>
      <div className={blockChartGraph}>
        <CubeQueryRender
          query={{
            measures: [courseCount],
            dimensions: [courseAuthorId, courseStatus],
            filters: props.filters,
          }}
          render={chartRender}
        />
      </div>
    </div>
  )
}

interface ICourseByAuthorChartStateProps {
  filters: Filter[]
}
type CourseByAuthorChartProps = ICourseByAuthorChartStateProps

const mapStateToProps = (store: IAppState): CourseByAuthorChartProps => {
  return {
    filters: store.manufacturerBranchStatistics.courseByAuthorFilters,
  }
}

export default connect<CourseByAuthorChartProps, unknown, unknown, IAppState>(mapStateToProps)(CourseByAuthorChart)
